import React from 'react'
import classObj from './CreditSymbol.module.css';

function CreditSymbol({ icon, creditsAvailable, handleClick, clickable=false }) {

  return (
    <div 
      onClick={() => {
        if(clickable) handleClick();
      }} 
      className={classObj.credit_symbol} style={{ display: 'flex', flexDirection: 'row' }}
    >
      <img style={{ width: '20px', height: '20px' }} src={icon} />
      <div style={{ marginLeft: '1px' }}>{creditsAvailable >= 0 ? `${creditsAvailable}` : `...`}</div>
    </div>
  )
}

export default CreditSymbol