import { GET_STATUS, GET_STATUS_SUCCESS, GET_STATUS_FAILURE, POST_PAYMENT_COMPLETED } from "redux/constants/status";

export function getStatus(id) {
    return {
        type: GET_STATUS,
        id
    };
}

export function setStatusSuccess(id, status) {
    return {
        type: GET_STATUS_SUCCESS,
        id,
        status
    };
}

export function getStatusFail() {
    return {
        type: GET_STATUS_FAILURE
    };
}

export function postPaymentCompleted(email,
    keywords,
    location,
    company,
    order_id) {
    return {
        type: POST_PAYMENT_COMPLETED,
        email,
        keywords,
        location,
        company,
        order_id
    };
}
