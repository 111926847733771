import { GET_HASH_TAGS, SET_HASH_TAGS, ERROR_HASH_TAGS } from '../constants/promoHashTag';

export const getHashTags = (id) => ({
    type: GET_HASH_TAGS,
    id
});

export const setHashTags = (id, response) => ({
    type: SET_HASH_TAGS,
    id,
    response
});

export const getHashTagsFailed = () => ({
    type: ERROR_HASH_TAGS
});
