import * as types from '../constants/execHistory';

const initialState = {
    id: '',
    execHistory: [],
    loading: false,
    error: false
};

const execHistory = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_EXEC_HISTORY:
            return {
                ...state,
                loading: true,
                error: false
            };

        case types.SET_EXEC_HISTORY:
            return {
                ...state,
                id: action.id,
                execHistory: action.execHistory,
                loading: false,
                error: false
            };

        case types.ERROR_EXEC_HISTORY:
            return {
                ...state,
                id: '',
                execHistory: [],
                error: true,
                authorized: false
            };

        default: return state;
    }
};

export default execHistory;