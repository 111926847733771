import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import clssNestedLink from "./Sidebar.module.css";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { activeRoute, getSearch } from "./Sidebar";
import Collapse from "@material-ui/core/Collapse";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import classObj from "./Sidebar.module.css";
import externalLinkIcon from "assets/img/adCopylink.png";
const useStyles = makeStyles(styles);

export default function NestedLink({ children, color, open, active, className, promotionMenu, routes, show, anchorEl, handlePopoverClose, handleDrawerToggle, ...props }) {
  const stylesObj = useStyles();
  return (
    <Fragment>
      {/* To ignore href check in anchor tag  */}
      {/* eslint-disable-next-line */}
      <a className={`${className} ${active ? classObj.active : ""} nestedOpen`} id="promotionsNest">
        {/* {open ? children : (routes &&
          routes.length &&
          <NavLink
            to={{
              pathname: routes[0].path,
              search: (routes[0].path.routes || []).includes(
                "/subjectLines" || "/emailSample" || "/promotion" || "/hashtags" || "/promoQuora" || "/promoStackOverflow" || "/promoReddit"
              )
                ? props.location.search
                : getSearch(props.location.search),
            }}
          >
            {children}
          </NavLink>)
        } */}
        {children}
      </a>
      {open ? (
        <Collapse in={true} style={{ marginLeft: 40, height: '100%', position: 'relative' }}>
            { open ? <div className={classObj.verticalDivider}></div> : <></>}

            {routes &&
              routes.length &&
              routes.map((prop, key) => {
                const listItemClasses = classNames({
                  [" " + stylesObj[color]]: activeRoute(prop.path),
                });

                const whiteFontClasses = classNames({
                  [" " + stylesObj.whiteFont]: activeRoute(prop.path),
                });
                return (
                  <NavLink
                    to={{
                      pathname: prop.path,
                      search: (prop.path.routes || []).includes(
                        "/subjectLines" || "/emailSample" || "/promotion" || "/hashtags" || "/promoQuora" || "/promoStackOverflow" || "/promoReddit"
                      )
                        ? props.location.search
                        : getSearch(props.location.search),
                    }}
                    // to={{ pathname: prop.path, search: props.location.search }}
                    className={stylesObj.item}
                    activeClassName={`active ${classObj.active}`}
                    key={key}
                    exact
                    onClick={handleDrawerToggle}>
                    <ListItem id={`${prop.path.substring(1) }`} style={{ height: 38 }} button className={stylesObj.itemLink + listItemClasses}>
                      <div style={{ width: "12%", height: 0.5, background: "#F74C54", margin: 10 }}></div>
                      <ListItemText
                        primary={prop.name === 'Ad Copy' ? 
                        <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '8px' }}>{prop.name}</span>
                            <img style={{ width: '15px', height: '15px' }} src={externalLinkIcon} />
                        </div>
                        : prop.name}
                        style={{ color: "black",fontWeight:"350" }}
                        className={classNames(stylesObj.itemLinkText, whiteFontClasses, classObj.boldText)}
                        disableTypography={true}
                      />
                    </ListItem>
                  </NavLink>
                );
              })
            }
        </Collapse>
      ) : (
        <div>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={show}
            anchorEl={anchorEl}
            anchorPosition={{left: '66', top: '380'}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            anchorReference='anchorPosition'
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ p: 0, pointerEvents: 'auto'}} onClick={handlePopoverClose}>
              {promotionMenu()}
            </Typography>
          </Popover>
        </div>
        )
      }
    </Fragment>
  );
}
