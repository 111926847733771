import * as types from '../constants/promoReddit';

const initialState = {
    id: '',
    reddit: [],
    found: 0,
    showing: 0,
    authorized: false,
    loading: false,
    error: false
};

const promoReddit = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_PROMO_REDDIT:
            return {
                ...state,
                loading: true,
                error: false
            };

        case types.SET_PROMO_REDDIT:
            return {
                ...state,
                id: action.id,
                reddit: action.reddit,
                found: action.found,
                showing: action.showing,
                authorized: action.authorized,
                loading: false,
                error: false
            };

        case types.ERROR_PROMO_REDDIT:
            return {
                ...state,
                id: '',
                reddit: [],
                found: 0,
                showing: 0,
                authorized: false,
                loading: false,
                error: true
            };

        default: return state;
    }
};

export default promoReddit;