import * as types from "../constants/search";

const initialState = {
  id: "",
  email: "",
  Keywords: "",
  Location: "",
  company: "",
  name: "",
  loading: false,
  error: false,
};

const getSearchDetails = (state = initialState, { type, ...action }) => {
  switch (type) {
    case types.GET_SEARCH:
      return {
        ...state,
        id: action.id,
        loading: true,
        error: false,
      };

    case types.SET_SEARCH:
      return {
        ...state,
        email: action.email,
        Keywords: action.keywords,
        Location: action.location,
        company: action.company,
        name: action.name,
        loading: false,
        error: false,
      };

    case types.ERROR_SEARCH:
      return {
        ...state,
        email: "",
        Keywords: "",
        Location: "",
        company: "",
        name: "",
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default getSearchDetails;
