
import {
    GET_CLUSTER_KEYWORDS, SET_CLUSTER_KEYWORDS, ERROR_CLUSTER_KEYWORDS,
    GET_CLUSTER_TILES, SET_CLUSTER_TILES, ERROR_CLUSTER_TILES
} from '../constants/contentCluster';


export const getClusterKeywords = (id) => ({
    type: GET_CLUSTER_KEYWORDS,
    id
});

export const setClusterKeywords = (id, clusterKeywords, found, showing, authorized) => ({
    type: SET_CLUSTER_KEYWORDS,
    id,
    clusterKeywords,
    found,
    showing,
    authorized
});

export const getClusterKeywordsFailed = () => ({
    type: ERROR_CLUSTER_KEYWORDS
});


export const getClusterTiles = (id) => ({
    type: GET_CLUSTER_TILES,
    id
});

export const setClusterTiles = (id, clusterTiles, found, showing, authorized) => ({
    type: SET_CLUSTER_TILES,
    id,
    clusterTiles,
    found,
    showing,
    authorized
});

export const getClusterTilesFailed = () => ({
    type: ERROR_CLUSTER_TILES
});