import { defaultFont, container, primaryColor } from "assets/jss/material-dashboard-react.js";

const footerStyle = {
  block: {
    color: "inherit",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block",
    ...defaultFont,
    fontWeight: "500",
    fontSize: "12px",
  },
  left: {
    float: "left!important",
    display: "block",
  },
  right: {
    padding: "15px 0",
    margin: "0",
    fontSize: "14px",
    marginLeft: "84%",
  },
  footer: {
    bottom: "0",
    // borderTop: "1px solid " + grayColor[11],
    padding: "15px 0",
    ...defaultFont,
  },
  container,
  "@media (max-width: 767px)": {
    container: {
      position: "fixed!important",
    },
    right: {
      marginLeft: "18px",
      float: "right!important",
    },
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent",
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0",
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto",
  },
};
export default footerStyle;
