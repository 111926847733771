import { OPEN_ALERT, CLOSE_ALERT } from '../constants/alert';

export const openAlert = (title, info) => ({
    type: OPEN_ALERT,
    title,
    info
});

export const closeAlert = () => ({
    type: CLOSE_ALERT
});
