
import { GET_KEYWORDS, SET_KEYWORDS, ERROR_KEYWORDS } from '../constants/keyword';

export const getKeywords = (id) => ({
    type: GET_KEYWORDS,
    id
});

export const setKeywords = (id, keywords, found, showing, authorized) => ({
    type: SET_KEYWORDS,
    id,
    keywords,
    found,
    showing,
    authorized
});

export const getKeywordsFailed = () => ({
    type: ERROR_KEYWORDS
});
