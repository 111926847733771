import { GET_PROMO_YOUTUBE, SET_PROMO_YOUTUBE, ERROR_PROMO_YOUTUBE } from "../constants/promoYoutube";

export const getPromoYoutube = (id) => ({
  type: GET_PROMO_YOUTUBE,
  id,
});

export const setPromoYoutube = (youtube) => ({
  type: SET_PROMO_YOUTUBE,
  youtube,
});

export const getPromoYoutubeFailed = () => ({
  type: ERROR_PROMO_YOUTUBE,
});
