import * as types from '../constants/abmInvoke';

const initialState = { 
    id:'',
    user_id: '',
    company_name: '',
    emails: [],
    response:'',
    data:'',
    loading: false,
    error: false
};

const abmInvoke = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {

        case types.GET_INVOKE:
            return {
                ...state,
                id: action.id,
                data: action.data,
                loading: true,
                error: false
            };

        case types.SET_INVOKE:
            return {
                ...state,
                user_id: action.user_id.id,
                loading: false,
                error: false
            };

        case types.ERROR_INVOKE:
            return {
                ...state,
                id: action.id,
                company_name: '',
                emails: [],
                loading: false,
                error: true
            };

        default: return state;
    }
};

export default abmInvoke;