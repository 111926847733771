import { primaryColor } from "assets/jss/material-dashboard-react";
import * as React from "react";

function HomeSVG(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={30} viewBox="0 0 40 30" {...props}>
      <defs>
        <linearGradient id="prefix__a" x1={0.5} x2={0.5} y2={1} gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#ff844f" />
          <stop offset={1} stopColor="#f74c54" />
        </linearGradient>
      </defs>
      <path
        data-name="Icon awesome-home"
        d="M19.469 10.036l-12.8 10.17v10.976a1.092 1.092 0 001.111 1.072l7.781-.019a1.092 1.092 0 001.105-1.072v-6.41a1.092 1.092 0 011.111-1.072h4.444a1.092 1.092 0 011.111 1.072v6.405a1.052 1.052 0 00.324.76 1.132 1.132 0 00.787.315l7.779.021a1.092 1.092 0 001.111-1.072V20.2l-12.8-10.162a.871.871 0 00-1.064-.002zm20.223 6.912l-5.805-4.615V3.057a.819.819 0 00-.833-.8h-3.89a.819.819 0 00-.833.8V7.92l-6.217-4.933a3.428 3.428 0 00-4.236 0L.3 16.948a.784.784 0 00-.11 1.132l1.771 2.076a.843.843 0 00.564.289.854.854 0 00.611-.18L19.469 7.29a.871.871 0 011.062 0l16.334 12.976a.854.854 0 001.174-.107l1.771-2.076a.784.784 0 00.185-.591.8.8 0 00-.3-.543z"
        transform="translate(.001 -2.254)"
        fill={props && props.className.includes("activeIcon") ? primaryColor[5] : "#a0a0a0"}
      />
    </svg>
  );
}

export default HomeSVG;
