import { put, takeLeading, call, select, delay } from 'redux-saga/effects';
import { GET, POST } from '../../utils/api.utility';
import { getTokenHeader } from 'redux/utility';

// import for TRENDING BLOGS 
import { GET_TRENDING_BLOGS, GENERATE_AI_BLOG } from '../constants/trendingBlogs';
import { setTrendingBlogs, getTrendingBlogs, getTrendingBlogsFailed, generateAiBlogFailed, setGenerateAiBlog } from '../actions/trendingBlogs';

// import for BLOGS HEADERS
import { GET_BLOG_HEADERS } from './../constants/blogHeaders';
import { setBlogHeaders, getBlogHeadersFailed } from './../actions/blogHeaders';

// import for BLOGS HEADERS
import { GET_CLOUD_WORDS } from './../constants/wordCloud';
import { setCloudWords, getCloudWordsFailed } from './../actions/wordCloud';

// Need Clean up
function* getTrendingBlogsById({ id }) {
    const header = yield select(getTokenHeader);
    var pollLimit = 20;
    yield put(getTrendingBlogs(id));

    while(pollLimit > 0) {
        try {
            const { top_posts, found, showing, authorized } = yield call(GET,
                `/top_posts?id=${id}`,
                header
            );

            if (id && top_posts) {
                pollLimit = 0;
                console.log('success');
                yield put(setTrendingBlogs(id, top_posts, found, showing, authorized));
            } else {
                pollLimit = pollLimit - 1;
                yield delay(5000);
                console.log('delay');
                if(pollLimit === 0) yield put(getTrendingBlogsFailed());
            }
        } catch (error) {
            pollLimit = pollLimit - 1;
            console.log('ERROR ', error);
            yield delay(5000);
            console.log('delay');
            if(pollLimit === 0) yield put(getTrendingBlogsFailed());
        }
    }
}

function* getBlogHeadersByUrl({ url }) {
    try {
        const header = yield select(getTokenHeader);

        const { headers, authorized } = yield call(POST,
            `/top_posts_headers`,
            header,
            { url: url }
        );

        if (url && headers) {
            yield put(setBlogHeaders(url, headers, authorized));
        } else {
            yield put(getBlogHeadersFailed());
        }
    } catch (error) {
        yield put(getBlogHeadersFailed());
    }
}

function* getCloudWordsByUrl({ url }) {
    try {
        const header = yield select(getTokenHeader);
        
        const  {words, title ,  authorized } = yield call(POST,
            `/top_posts_wordcloud`,
            header,
            { url: url }
        );
            let data = "";
        if (title){
            data = [ {words:words}, {title:title} ];
        }

        if (url && data) {
            yield put(setCloudWords(url, data, authorized));
        } else {
            yield put(getCloudWordsFailed());
        }
    } catch (error) {
        yield put(getCloudWordsFailed());
    }
}

export default function* trendingBlog() {
    yield takeLeading(GET_TRENDING_BLOGS, getTrendingBlogsById);
    yield takeLeading(GET_BLOG_HEADERS, getBlogHeadersByUrl);
    yield takeLeading(GET_CLOUD_WORDS, getCloudWordsByUrl);
}
