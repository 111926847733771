
import { GET_REPORT, GET_REPORT_ID, SET_REPORT, ERROR_REPORT } from '../constants/abmReport';

export const getReport = (id) => ({
    type: GET_REPORT,
    id
});

export const getReportId = (id) => {
    return {
        type: GET_REPORT_ID,
        id
    }
};

export const setReport = (data) => {
    return {
        type: SET_REPORT,
        data
    }
};

export const getReportFailed = () => ({
    type: ERROR_REPORT
});
