function DateFormatter(date) {
  let dateFormat = date.split(" - ");
  let splitDate = dateFormat[0].split("-");
  let day = parseInt(splitDate[0]);
  let month = parseInt(splitDate[1]);
  let year = parseInt(splitDate[2]);
  var string_to_Date = new Date(year, month, day);
  return string_to_Date;
}

function desc(a, b, orderBy) {
  if (orderBy === 2) {
    let Date_a = DateFormatter(a[orderBy]);
    let Date_b = DateFormatter(b[orderBy]);
    if (Date_b < Date_a) {
      return -1;
    }
    if (Date_b > Date_a) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export function stableSort(array, currOrder, orderBy, sort = getSorting) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = sort(currOrder, orderBy)(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
