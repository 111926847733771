
import { GET_CCL_TITLES, SET_CCL_TITLES, ERROR_CCL_TITLES } from '../constants/mindMapTitle';

export const getCclTitlesMindmap = (id) => ({
    type: GET_CCL_TITLES,
    id
});

export const setCclTitlesMindmap = (id, cclTitlesMindmap, found, showing, authorized) => ({
    type: SET_CCL_TITLES,
    id,
    cclTitlesMindmap,
    found,
    showing,
    authorized
});

export const getCclTitlesMindmapFailed = () => ({
    type: ERROR_CCL_TITLES
});
