import { GET_PROMO_QUORA, SET_PROMO_QUORA, ERROR_PROMO_QUORA } from '../constants/promoQuora';

export const getPromoQuora = (id) => ({
    type: GET_PROMO_QUORA,
    id
});

export const setPromoQuora = (id, quora, found, showing, authorized) => ({
    type: SET_PROMO_QUORA,
    id,
    quora,
    found,
    showing,
    authorized
});

export const getPromoQuoraFailed = () => ({
    type: ERROR_PROMO_QUORA
});
