import { Drawer, IconButton, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Cancel from "@material-ui/icons/Cancel";
import classObj from "./CreditDrawer.module.css";
import CustomTable from "components/CustomTable/CustomTable";
import Loader from "components/Loader/Loader";
import coins_icon from '../../assets/img/coins_icon.png';
import CreditSymbol from "components/CreditSymbol/CreditSymbol";
const container = window !== undefined ? () => window.document.body : undefined;
export default function CreditDrawer({ open, toggleDrawer, credit, creditsUsage }) {
  const [isDrawer, setIsDrawer] = useState(false);
  const tableHead = ["Credit Type", "Credits", "Date"];
  useEffect(() => {
    setIsDrawer(open);
  }, [open]);
  return (
    <Drawer anchor="right" variant="temporary" open={isDrawer} container={container} onClose={toggleDrawer} classes={{ paper: classObj.drawer }}>
      <div className={classObj.headerContainer}>
        <h4 className={classObj.headerTitle}>Your Organization Credits</h4>

        <IconButton onClick={toggleDrawer}>
          <Cancel style={{ color: "#fff" }} />
        </IconButton>
      </div>
      <Grid container>
        <Grid item xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
          <div className={classObj.bodyContainer}>
            <div style={{ lineHeight: '1em', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="h6" className={classObj.creditCounter}>
                Available Credits: 
              </Typography>
              <span style={{ marginLeft: '5px', border: '1px solid #bfbaba', borderRadius: '4px' }}>
                <CreditSymbol clickable={false} icon={coins_icon} creditsAvailable={credit}/>
              </span>
              <span onClick={() => window.open("https://marxeed.com#pricing", "_blank")} style={{ cursor: 'pointer', fontWeight: '900', padding: '3px 5px', backgroundColor: '#ff0000', fontSize: '16px', borderRadius: '4px', color: '#fff' }}>
                  +
              </span>
            </div>
          </div>
          {creditsUsage && creditsUsage.length ? (
            <CustomTable tableHead={tableHead} tableData={creditsUsage} defaultOrderBy={2} pointer={false} creditDrawerStyle={true} />
          ) : (
            <Loader active={!creditsUsage} />
          )}
        </Grid>
      </Grid>
    </Drawer>
  );
}
