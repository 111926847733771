import React from "react";
import { primaryColor } from "assets/jss/material-dashboard-react";

function ResourceIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24" {...props}>
      <path
         d="M12 3l9 4.5-9 4.5-9-4.5L12 3zm4.5 7.25L21 12.5 12 17l-9-4.5 4.5-2.25h0m9 5L21 17.5 12 22l-9-4.5 4.5-2.25h0"
        fill={props && props.className.includes("activeIcon") ? primaryColor[5] : "#a0a0a0"}
      />
    </svg>
  );
}

export default ResourceIcon;