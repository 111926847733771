import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Typography } from "@material-ui/core";
import classes from "./Navbar.module.css";
//import TextLoop from "react-text-loop";
import { getExecHistory } from "../../redux/actions/execHistory";
import { getExecDate } from "utils/helper";
//import { getQueryParam } from "./../../utils/helper";

// const DO_MARXEED = [
//   "Get longtail keywords",
//   "Find accounts to target",
//   "Help decide the content cluster",
//   "Find the trending blogs",
//   "Generate email subject line using AI",
//   "Suggest promotion avenue with hastags",
// ];

const ReportLoading = React.memo(function({ getExecHistory, Keywords, Location, completed, id, listh, loginSuccess, isQparam }) {
  // const renderLoadingText = useMemo(() => {
  //   return (
  //     <>
  //       {" "}
  //       <TextLoop mask={true} fade={true}>
  //         {DO_MARXEED.map((suggestion, i) => {
  //           return (
  //             <Typography key={i} variant="body2" className={classes.doSuggestions} style={{ marginTop: 2 }}>
  //               {suggestion + "..."}
  //             </Typography>
  //           );
  //         })}
  //       </TextLoop>
  //     </>
  //   );
  // }, []);

  useEffect(() => {
    if (loginSuccess) {
      getExecHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginSuccess]);

  const renderExecutionTime = () => {
    let dateTimeFormat;
    if (listh.length) {
      dateTimeFormat = getExecDate(listh, id);
    }
    return (
      <Typography variant="body2" className={classes.doSuggestions}>
        {dateTimeFormat && "Run on: " + dateTimeFormat}
      </Typography>
    );
  };

  return (
    <div className={classes.searchDetails}>
      {!isQparam ? null : (
        <>
          <p className={classes.keywords}>Keywords: {Keywords}</p>
          <div className={classes.locationsContainer}>
            <p className={classes.location}>
              Location:{" "}
              {Location
                ? Location.toString()
                    .split(",")
                    .join(", ")
                : "NA"}
            </p>
            <div className={classes.suggestionsContainer}>{renderExecutionTime()}</div>
          </div>
        </>
      )}
    </div>
  );
});

const mapStateToProps = (state) => {
  const { search, status, execHistory, auth } = state;

  return {
    ...status,
    Keywords: search.Keywords,
    Location: search.Location,
    id: status.id,
    loginSuccess: auth.loginSuccess,
    listh: execHistory.execHistory,
  };
};
const mapDispatchToProps = {
  getExecHistory,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportLoading);
