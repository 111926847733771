import * as types from '../constants/wordCloud';

const initialState = {
    url: '',
    cloudWords: [],
    authorized: false,
    title: '',
    loading: false,
    error: false
};


const cloudWords = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_CLOUD_WORDS:
            return {
                ...state,
                loading: true,
                error: false,
            };

        case types.SET_CLOUD_WORDS:
            return {
                ...state,
                url: action.url,
                cloudWords: action.cloudWords && action.cloudWords[0] ? action.cloudWords[0].words : [],
                title: action.cloudWords,
                authorized: action.authorized,
                loading: false,
                error: false
            };

        case types.ERROR_CLOUD_WORDS:
            return {
                ...state,
                url: '',
                cloudWords: [],
                title: '',
                loading: false,
                error: true,
                authorized: false
            };

        default: return state;
    }
};

export default cloudWords;