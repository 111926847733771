import * as types from '../constants/trendingBlogs';
import * as typesGeneratedAiBlogs from '../constants/generateAiBlogs';

const initialState = {
    prompt: '',
    authorized: false,
    loading: false,
    error: false,
    data:[],
    // blog generating state in progress for trending blog
    trendingBlogsIsBlogInProgress: false,

    // content generating state in progress for Social
    socialIsBlogInProgress: false,

    // content generating state in progress for AiGenerated
    aiGeneratedBlogsIsPostInProgress: false,

    // content generating state in progress for My Youtube Channel
    youtubeChannelIsBlogInProgress: false,
    youtubeChannelIsTitleAndChaptersInProgress: false,
};

const generateAiBlog = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GENERATE_AI_BLOG:
            return {
                ...state,
                loading: true,
                error: false
            };
        case types.GET_AI_BLOG:
                return {
                    ...state.data,
            };    
        case types.SET_GENERATE_AI_BLOG:
            return {
                ...state,
                prompt: action.prompt,
                data: action.data,
                authorized: action.authorized,
                loading: false,
                error: false
            };

        case typesGeneratedAiBlogs.SET_TRENDINGBLOGS_BLOG_STATE:
            return {
                ...state,
                trendingBlogsIsBlogInProgress: action.isInProgress
            };

        case typesGeneratedAiBlogs.SET_SOCIALBLOGS_BlOG_STATE:
            return {
                ...state,
                socialIsBlogInProgress: action.isInProgress
            };

        case typesGeneratedAiBlogs.SET_AIGENERATEDBLOGS_POST_STATE:
            return {
                ...state,
                aiGeneratedBlogsIsPostInProgress: action.isInProgress
            };

        case typesGeneratedAiBlogs.SET_YOUTUBE_BlOG_STATE:
            return {
                ...state,
                youtubeChannelIsBlogInProgress: action.isInProgress
            };

        case typesGeneratedAiBlogs.SET_YOUTUBE_TITLEANDCHAPTERS_STATE:
            return {
                ...state,
                youtubeChannelIsTitleAndChaptersInProgress: action.isInProgress
            };

        case types.ERROR_GENERATE_AI_BLOG:
            return {
                ...state,
                prompt: '',
                data: [],
                loading: false,
                error: true,
                authorized: false
            };

        default: return state;
    }
};

export default generateAiBlog;