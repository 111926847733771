import {
  drawerWidth,
  transition,
  boxShadow,
  defaultFont,
  primaryColor,
  //primaryBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
  blackColor,
  darkGray,
  lightGray,
  hexToRgb,
} from "assets/jss/material-dashboard-react.js";
const mediaWidth = window.screen.width;
const sidebarStyle = (theme) => ({
  drawerPaper: {
    border: "none",
    position: "fixed",
    //top: "115px",
    overflow: "hidden",
    bottom: "0",
    left: "0",
    zIndex: "1",
    ...boxShadow,
    width: drawerWidth,
    borderTopRightRadius: mediaWidth < 960 ? 0 : 8,
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      position: "fixed",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
      ...boxShadow,
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0px",
      transform: `translate3d(${drawerWidth}px, 0, 0)`,
      ...transition,
    },
  },
  collapseBtn: {
    cursor: 'pointer',
    border: 'none',
    marginTop: '15px'
  },
  logo: {
    position: "relative",
    padding: "15px 50px 10px 15px",
    zIndex: "4",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
      backgroundColor: "rgba(" + hexToRgb(grayColor[6]) + ", 0.3)",
    },
  },
  logoLink: {
    ...defaultFont,
    display: "block",
    fontSize: "18px",
    textAlign: "center",
    fontWeight: "400",
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: darkGray,
    },
  },
  logoImage: {
    width: "170px",
  },
  img: {
    width: "35px",
    top: "22px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0",
  },
  background: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    top: "0",
    left: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    "&:after": {
      position: "absolute",
      zIndex: "3",
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: whiteColor,
      opacity: ".92",
    },
  },
  list: {
    //marginTop: "5px",
    paddingLeft: "5px",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset",
  },
  item: {
    position: "relative",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: whiteColor,
    },
  },
  itemLink: {
    width: "auto",
    transition: "all 300ms linear",
    height: 45,
    // margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    // padding: "10px 0px 10px 15px",
    backgroundColor: "transparent",
    ...defaultFont,
    fontSize: "13px",
  },
  itemIcon: {
    width: "25px",
    height: "50px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    // marginTop: "-5px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(" + hexToRgb(lightGray) + ", 1)",
  },
  itemIconLetter: {
    width: "24px",
    height: "30px",
    fontSize: "13px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
    color: "rgba(" + hexToRgb(darkGray) + ", 0.8)",
  },
  itemText: {
    ...defaultFont,
    fontSize: "14px",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "600",
    paddingLeft: '5px',
    color: "rgba(" + hexToRgb(darkGray) + ", 0.8)",
  },
  itemLinkText: {
    ...defaultFont,
    margin: "0",
    fontSize: "13px",
    whiteSpace: "pre-wrap",
    color: darkGray,
  },
  whiteFont: {
    color: primaryColor[5] + "!important",
  },
  primary: {
    // backgroundColor: primaryColor[0],
    // ...primaryBoxShadow,
    "&:hover,&:focus": {
      // backgroundColor: primaryColor[0],
      // ...primaryBoxShadow
    },
  },
  blue: {
    backgroundColor: infoColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(infoColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(infoColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: infoColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(infoColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(infoColor[0]) +
        ",.2)",
    },
  },
  green: {
    backgroundColor: successColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(successColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(successColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: successColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(successColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ",.2)",
    },
  },
  orange: {
    backgroundColor: warningColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(warningColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(warningColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: warningColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(warningColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ",.2)",
    },
  },
  red: {
    backgroundColor: dangerColor[0],
    boxShadow:
      "0 12px 20px -10px rgba(" +
      hexToRgb(dangerColor[0]) +
      ",.28), 0 4px 20px 0 rgba(" +
      hexToRgb(blackColor) +
      ",.12), 0 7px 8px -5px rgba(" +
      hexToRgb(dangerColor[0]) +
      ",.2)",
    "&:hover,&:focus": {
      backgroundColor: dangerColor[0],
      boxShadow:
        "0 12px 20px -10px rgba(" +
        hexToRgb(dangerColor[0]) +
        ",.28), 0 4px 20px 0 rgba(" +
        hexToRgb(blackColor) +
        ",.12), 0 7px 8px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ",.2)",
    },
  },
  sidebarWrapper: {
    position: "relative",
    height: "calc(100vh - 125px)",
    overflow: "auto",
    width: drawerWidth,
    //zIndex: "4",
    overflowScrolling: "touch",
    display: "flex",
    flexFlow: "column nowrap",
    overflowY:"contain"
        // justifyContent: "space-between",
  },
  activePro: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "100%",
      bottom: "13px",
    },
  },
  divider: {
    width: "100%",
    height: 1,
    background: primaryColor[0],
    margin: 10,
  },
  sidebarWrapper1: {
    position: "relative",
    //height: "calc(100vh - 125px)",
    overflow: "auto",
    width: "260px",
    zIndex: "4",
    overflowScrolling: "touch",
    display: "flex",
    flexFlow: "column nowrap",
    overflowY:"contain"
        // justifyContent: "space-between",
  }
});


export default sidebarStyle;
