import { axiosInstance } from "api/axios/axios";

const getYoutubeVideoId = (youtubeLink) => {
  var ind = youtubeLink.indexOf('=')
  var video_id = ""
  if(ind !== -1) video_id = youtubeLink.slice(ind + 1);
  ind = youtubeLink.lastIndexOf("/");
  if(ind !== -1 && video_id.length === 0) {
    video_id = youtubeLink.slice(youtubeLink.lastIndexOf("/") + 1)
  }
  if(video_id.length === 0) {
    console.log("Failed to load video details for the given video link");
    return null;
  }
  console.log(video_id)
  return video_id;
}

export const generateAi = async (prompt, setGeneratedBlog, credits= 0, max_blog_length= 0, blogType= 'blog', youtubeLink= '') => {
  try {
    const tokenHeader = JSON.parse(localStorage.getItem("marxeed_cred")).tokenId;
    const campaignId = localStorage.getItem("marxeed_transaction_id") || 'd8adb432-a9a9-11ed-9838-06e57c50aeea';
    var data;
    if(blogType === 'blog') {
      data = {
        "prompt": prompt,
        "campaign_id": campaignId,
      }
    }
    else {
      data = {
        "prompt": prompt,
        "campaign_id": campaignId,
        "credits": `-${credits}`,
        "response_length": max_blog_length,
        "youtube_link": youtubeLink
      }
    }
    const res = await axiosInstance({
      method: "POST",
      url: `/generate_content/v1/?generate_content=${blogType}`,
      data: data
    });
    if(res) {
      let resId = res.data.response_id;
      let counter = 0
      if(resId !== ""){
        const interval = setInterval(async () => {
          try{
            const resp = await axiosInstance({
              method: "GET",
              url: `/generate_content/v1/${campaignId}?response_id=${resId}`,
            });
            if(resp.data.Items[0].response !== '') {
              clearInterval(interval)
              setGeneratedBlog(resp.data.Items[0].response, true, resId);
              return resp.data.Items[0].response;
            }
            counter += 1;
            if(counter > 50){
              clearInterval(interval)
              setGeneratedBlog("Taking longer then usual you would receive email notification after successfull generation.", false)
              return resp.data.Items[0].response;
            }
          }
          catch(err) {
            clearInterval(interval)
            setGeneratedBlog("Taking longer then usual you would receive email notification after successfull generation.", false)
            console.log(err);
          }
          
        }, 5000);
      }
    }
  } catch (e) {
    setGeneratedBlog("Taking longer then usual you would receive email notification after successfull generation.", false)
    console.log(e);
  }
};

export const listAiBlogs = async () => {
  try {
    const tokenHeader = JSON.parse(localStorage.getItem("marxeed_cred")).tokenId;
    const campaignId = localStorage.getItem("marxeed_transaction_id") || 'd8adb432-a9a9-11ed-9838-06e57c50aeea';

    const resp = await axiosInstance({
      method: "GET",
      url: `/generate_content/v1/list/${campaignId}?generate_content=blog`,
      headers: {
        Authorization: "Bearer " + tokenHeader,
      }
    });

    var response = resp?.data?.filter((blog) => {
      if(blog?.title?.length > 0) return blog;
      return;
    })
    console.log(response);

    return response
  } catch (error) {
    console.log(error);
  }
}

export const generateAiEmailSubjectLines = async (prompt, tableData, setGeneratedEmailSubjectLines) => {
  try {
    const tokenHeader = JSON.parse(localStorage.getItem("marxeed_cred")).tokenId;
    const campaignId = localStorage.getItem("marxeed_transaction_id") || 'd8adb432-a9a9-11ed-9838-06e57c50aeea';
    const res = await axiosInstance({
      method: "POST",
      url: `/generate_content/v1?generate_content=email_subject_line`,
      headers: {
        Authorization: "Bearer " + tokenHeader,
      },
      data: {
        "prompt": prompt,
        "campaign_id": campaignId
      }
    });

    setTimeout(() => {
      listAiEmailSubjectLines(setGeneratedEmailSubjectLines, tableData)
    }, 5000);
  } catch (error) {
    return null;
  }
}

const pollEmailList = async (interval, counter, setGeneratedEmailSubjectLines) => {
  const tokenHeader = JSON.parse(localStorage.getItem("marxeed_cred")).tokenId;
  const campaignId = localStorage.getItem("marxeed_transaction_id") || 'd8adb432-a9a9-11ed-9838-06e57c50aeea';

  try{
    const resp = await axiosInstance({
      method: "GET",
      url: `/generate_content/v1/list/${campaignId}?generate_content=email_subject_line`,
      headers: {
        Authorization: "Bearer " + tokenHeader,
      }
    });
    if(resp?.data?.length) {
      if(interval) clearInterval(interval)
      setGeneratedEmailSubjectLines(resp?.data)
      return resp?.data;
    }
    counter += 1;
    if(counter > 10 || resp?.data?.length === 0){
      if(interval) clearInterval(interval)
      setGeneratedEmailSubjectLines([])
      return [];
    }
  }
  catch(err) {
    if(interval) clearInterval(interval)
    setGeneratedEmailSubjectLines([])
    console.log(err);
  }
}

export const listAiEmailSubjectLines = async (setGeneratedEmailSubjectLines, tableData) => {
  try {
    let counter = 1;
    pollEmailList(null, counter, setGeneratedEmailSubjectLines);
    const interval = setInterval(async () => {
      pollEmailList(interval, counter, setGeneratedEmailSubjectLines);
    }, 5000);
  } catch (error) {
    console.log(error);
  }
}

// get transcript
export const getTranscript = async (youtubelink) => {
  try {
    const video_id = getYoutubeVideoId(youtubelink);
    const res = await axiosInstance({
      method: "GET",
      url: `/generate_content/v1/get_transcript/${video_id}`,
    });

    return res;
  } catch (error) {
    return null;
  }
}

export const getYoutubeVideos = async (youtubelink) => {
  try {
    const startIndex = youtubelink.indexOf('@');
    if(startIndex === -1) return null;
    const channel_id = youtubelink.slice(startIndex);
    const res = await axiosInstance({
      method: "GET",
      url: `/generate_content/v1/yt_channel/list?channel_id=${channel_id}`,
    });

    return res?.data;
  } catch (error) {
    return null;
  }
}

export const getYoutubeVideo = async (videoLink) => {
  try {
    const videoId = getYoutubeVideoId(videoLink);
    const res = await axiosInstance({
      method: "GET",
      url: `/generate_content/v1/yt_channel/list?link_id=${videoId}`,
    });

    return res?.data;
  } catch (error) {
    return null;
  }
}

export const generateYoutubeTitleAndChapters = async (prompt, generateTitleChapterCallback, youtubeLink, channelId) => {
  try {
    const tokenHeader = JSON.parse(localStorage.getItem("marxeed_cred")).tokenId;
    const campaignId = localStorage.getItem("marxeed_transaction_id") || 'd8adb432-a9a9-11ed-9838-06e57c50aeea';
    var data;
    data = {
      "prompt": prompt,
      "campaign_id": campaignId,
      "youtube_link": youtubeLink,
      "channel_id": channelId,
      "response_length": 500
    }

    const res = await axiosInstance({
      method: "POST",
      url: `/generate_content/v1/?generate_content=social_yt_chapters_title`,
      headers: {
        Authorization: "Bearer " + tokenHeader,
      },
      data: data
    });
    if(res) {
      let resId = res.data.response_id;
      let counter = 0
      setTimeout(() => {
        if(resId !== ""){
          const interval = setInterval(async () => {
            try{
              const resp = await axiosInstance({
                method: "GET",
                url: `/generate_content/v1/${campaignId}?response_id=${resId}`,
                headers: {
                  Authorization: "Bearer " + tokenHeader,
                },
              });
              if(resp?.data?.Items[0]?.response?.length) {
                clearInterval(interval)
                generateTitleChapterCallback(resp.data.Items[0]?.response, true)
                return resp.data.Items[0]?.response;
              }
              counter += 1;
              if(counter > 50){
                clearInterval(interval)
                generateTitleChapterCallback("Taking longer then usual you would receive email notification after successfull generation.", false)
                return resp.data.Items[0]?.response;
              }
            }
            catch(err) {
              clearInterval(interval)
              generateTitleChapterCallback("Taking longer then usual you would receive email notification after successfull generation.", false)
              console.log(err);
            }
            
          }, 5000);
        }
      }, 5000)
    }
  } catch (e) {
    generateTitleChapterCallback("Taking longer then usual you would receive email notification after successfull generation.", false)
    console.log(e);
  }
};

export const getYoutubeTitleAndChapters = async (channelId, youtubeLink) => {
  console.log(channelId)
  const video_id = getYoutubeVideoId(youtubeLink);
  try {
    const res = await axiosInstance({
      method: "GET",
      url: `/generate_content/v1/yt_channel/list?channel_id=${channelId}&content_type=ai&link=${video_id}`,
    });

    return res?.data;
  } catch (error) {
    return null;
  }
}

export const updateYoutubeTitleAndChapters = async (updatePayload, channelId, youtubeLink) => {
  const video_id = getYoutubeVideoId(youtubeLink);
  try {
    const res = await axiosInstance({
      method: "PUT",
      url: `/generate_content/v1/yt_channel/update?channel_id=${channelId}&link=${video_id}`,
      data: updatePayload
    });

    return res?.data;
  } catch (error) {
    return null;
  }
}

export const generateAiPost = async (prompt, setGeneratedPostCallback, responseId= '', campaign_id= '') => {
  try {
    const tokenHeader = JSON.parse(localStorage.getItem("marxeed_cred")).tokenId;
    const campaignId = campaign_id || localStorage.getItem("marxeed_transaction_id") || 'd8adb432-a9a9-11ed-9838-06e57c50aeea';
    var data;
    data = {
      "prompt": prompt,
      "campaign_id": campaignId,
      'response_id': responseId
    }

    const res = await axiosInstance({
      method: "POST",
      url: `/generate_content/v1/?generate_content=social_post`,
      headers: {
        Authorization: "Bearer " + tokenHeader,
      },
      data: data
    });
    if(res) {
      let resId = res.data.response_id;
      let counter = 0
      setTimeout(() => {
        if(resId !== ""){
          const interval = setInterval(async () => {
            try{
              const resp = await axiosInstance({
                method: "GET",
                url: `/generate_content/v1/${campaignId}?response_id=${resId}`,
                headers: {
                  Authorization: "Bearer " + tokenHeader,
                },
              });
              if(resp?.data?.Items[0]?.social_post?.length) {
                clearInterval(interval)
                setGeneratedPostCallback(resp.data.Items[0]?.social_post, true)
                return resp.data.Items[0]?.social_post;
              }
              counter += 1;
              if(counter > 50){
                clearInterval(interval)
                setGeneratedPostCallback("Taking longer then usual you would receive email notification after successfull generation.", false)
                return resp.data.Items[0]?.social_post;
              }
            }
            catch(err) {
              clearInterval(interval)
              setGeneratedPostCallback("Taking longer then usual you would receive email notification after successfull generation.", false)
              console.log(err);
            }
            
          }, 5000);
        }
      }, 5000)
    }
  } catch (e) {
    setGeneratedPostCallback("Taking longer then usual you would receive email notification after successfull generation.", false)
    console.log(e);
  }
};


// get company description
export const getCompanyDescription = async(link, saved = false) => {
  try {

    var url = `/generate_content/v1/company_details?company_url=${link}`;
    if(saved) url = url + '&saved=True';
    const response = await axiosInstance({
      method: "GET",
      url: url,
    });

    console.log(response);
    return response.data.company_description;
  } catch (error) {
    console.log(error);
    return 'Failed to get the company description.';
  }
}

// update company description
export const updateCompanyDescription = async(link, description, saved = false) => {
  try {

    var url = `/generate_content/v1/company_details?company_url=${link}`;
    const response = await axiosInstance({
      method: "PUT",
      url: url,
      data: {
        'company_description': description
      }
    });

    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return 'Failed to get the company description.';
  }
}


