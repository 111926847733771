import { GET_PROMO_STACK, SET_PROMO_STACK, ERROR_PROMO_STACK } from '../constants/promoStack';

export const getPromoStack = (id) => ({
    type: GET_PROMO_STACK,
    id
});

export const setPromoStack = (id, stackoverflow, found, showing, authorized) => ({
    type: SET_PROMO_STACK,
    id,
    stackoverflow,
    found,
    showing,
    authorized
});

export const getPromoStackFailed = () => ({
    type: ERROR_PROMO_STACK
});
