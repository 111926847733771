import React, { Suspense } from "react";
// @material-ui/icons
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Loader from "components/Loader/Loader";
//import AccountTreeIcon from "@material-ui/icons/AccountTree";
//import ContactsIcon from "@material-ui/icons/Contacts";
import TrendingBlogsSVG from "./assets/jss/material-dashboard-react/svgs/TrendingBlogsSVG.js";
import ContentClusterSVG from "assets/jss/material-dashboard-react/svgs/ContentClusterSVG.js";
import PromotionSVG from "assets/jss/material-dashboard-react/svgs/PromotionsSVG.js";
import HomeSVG from "assets/jss/material-dashboard-react/svgs/HomeSVG.js";
import SiteSVG from "assets/jss/material-dashboard-react/svgs/SiteSVG.js";
import MyYoutubeChannelSVG from "assets/jss/material-dashboard-react/svgs/MyYoutubeChannelSVG.js";
import AiGeneratedBlogsSVG from "assets/jss/material-dashboard-react/svgs/AiGeneratedBlogsSVG.js";
//import ResourcesSVG from "assets/jss/material-dashboard-react/svgs/ResourceSVG.js"
import ResourceIcon from "assets/jss/material-dashboard-react/svgs/ResourceSVG.js";
import SidebarItem from "components/SidebarItem/SidebarItem.js";


//Route Components
const Report = React.lazy(() => import("./views/Home/Report.js"));
// const ContentCluster = React.lazy(() => import("./views/ContentCluster/ContentCluster.js"));
const TrendingBlogs = React.lazy(() => import("./views/TrendingBlogs/TrendingBlogs.js"));
const BlogCuration = React.lazy(() => import("./views/BlogCuration/BlogCuration.js"));
const OffPageSeo = React.lazy(() => import("./views/PromoOffPageSeo/OffPageSeo.js"));
const PromoHashTag = React.lazy(() => import("./views/PromoHashTag/PromoHashTag.js"));
const PromoGeneratedBlogs = React.lazy(() => import("./views/PromoGeneratedBlogs/PromoGeneratedBlogs.js"));
const PromoQa = React.lazy(() => import("./views/PromoQASites/PromoQa.js"));
const PromoYoutube = React.lazy(() => import("./views/PromoYoutube/PromoYoutube.js"));
const PromoLinkedIn = React.lazy(() => import("./views/PromoLinkedIn/PromoLinkedIn.js"));
const PromoEbook = React.lazy(() => import("./views/PromoEbook/PromoEbook.js"));
const OutboundAi = React.lazy(() => import("./views/OutboundAi/OutboundAi.js"));
const MyYoutubeChannel = React.lazy(() => import("./views/MyYoutubeChannel/MyYoutubeChannel.js"));
const TargetAccounts = React.lazy(() => import("./views/TargetAccounts/TargetAccounts.js"));
const Resouces = React.lazy(() => import("./views/Resources/resources.js"));
const PromoAdCopy = React.lazy(() => import("./views/PromoAdCopy/PromoAdCopy"));

//const AccountsBasedMarketing = React.lazy(() => import("./views/AccountsBasedMarketing/AccountsBasedMarketing.js"));
//const LeadData = React.lazy(() => import("./views/LeadData/LeadData.js"));
const renderFunc = function (RouteComponent) {
  return function (routeProps) {
    return (
      <Suspense fallback={<Loader active />}>
        <RouteComponent {...routeProps} />
      </Suspense>
    );
  };
};

const AiIcon = () => {
  return <div style={{ width: '25px', height: '25px', marginRight: '15px' }}><img style={{ width: '25px', height: '25px' }} src="https://img.icons8.com/external-xnimrodx-lineal-gradient-xnimrodx/64/null/external-blogger-hobbies-and-free-time-xnimrodx-lineal-gradient-xnimrodx.png"/></div>
}

const AiName = (name) => {
  return (
    <p>{name} <SidebarItem /></p>
  )
}

const dashboardRoutes = [
  {
    path: "/home",
    name: "Home",
    icon: HomeSVG,
    render: renderFunc(Report), //Previously it Home and Report were different pages ~ Report Page is redesigned as Home Page
  },
  // {
  //   path: "/contentCluster",
  //   name: "Content Cluster",
  //   icon: ContentClusterSVG,
  //   render: renderFunc(ContentCluster),
  // },
  {
    path: "/aigeneratedblogs",
    name: AiName("AI Generated Blogs"),
    icon: AiIcon,
    render: renderFunc(PromoGeneratedBlogs),
  },
  {
    path: "/trendingBlogs",
    name: AiName("Trending Blogs"),
    icon: TrendingBlogsSVG,
    render: renderFunc(TrendingBlogs),
  },
  {
    path: "/promotion",
    name: "Promotions",
    icon: PromotionSVG,
    render: renderFunc("/home"),
    isNested: true,
    routes: [
      // {
      //   name: "Off Page SEO",
      //   icon: "O",
      //   path: "/offpageseo",
      //   render: renderFunc(OffPageSeo),
      // },
      // {
      //   name: "Hashtags",
      //   icon: "#",
      //   path: "/hashtags",
      //   render: renderFunc(PromoHashTag),
      // },
      {
        name: "Q&A Sites",
        icon: "Q",
        path: "/qasites",
        render: renderFunc(PromoQa),
      },
      {
        name: AiName("Youtube"),
        icon: "S",
        path: "/Youtube",
        render: renderFunc(PromoYoutube),
      },
      {
        name: "LinkedIn",
        icon: "S",
        path: "/LinkedIn",
        render: renderFunc(PromoLinkedIn),
      },
      {
        name: "eBooks",
        icon: "E",
        path: "/Ebook",
        render: renderFunc(PromoEbook),
      },
      {
        name: "Ad Copy",
        icon: "A",
        path: "/adCopy",
        render: renderFunc(PromoAdCopy),
      },
    ],
  },
  {
    path: "/outboundai",
    name: "Outbound AI",
    icon: SiteSVG,
    render: renderFunc(OutboundAi),
  },
  {
    path: "/myyoutubechannel",
    name: AiName("My YouTube Channel"),
    icon: MyYoutubeChannelSVG,
    render: renderFunc(MyYoutubeChannel),
  },
  // {
  //   path: "/toTarget",
  //   name: "Accounts To Target",
  //   icon: AssignmentInd,
  //   render: renderFunc(TargetAccounts),
  // },
  {
    path: "/blogCuration",
    name: "Blog Optimizer",
    icon: ContentClusterSVG,
    render: renderFunc(BlogCuration),
  },
  {
    path: "/resources",
    name: "Templates & Resources",
    icon: ResourceIcon,
    render: renderFunc(Resouces),
  },
  // {
  //   path: "/leaddata",
  //   name: "Lead Data",
  //   icon: ContactsIcon,
  //   render: renderFunc(LeadData),
  // },
];

export default dashboardRoutes;
