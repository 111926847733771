export const homeSteps = [
  {
    selector: "#actionButton",
    content: "Please click on this button to create a campaign.",
  },
  // {
  //   selector: "#contentCluster",
  //   content: "Please click here to view the content cluster.",
  // },
  {
    selector: "#aigeneratedblogs",
    content: "Click here to view AI generated blogs.",
  },
  {
    selector: "#trendingBlogs",
    content: "Please click here to view the trending blogs.",
  },
  {
    selector: "#promotion",
    content: "Please click here to explore content promotions",
  },
  {
    selector: "#qasites",
    content: "Please click on this button to view Q&A sites.",
  },
  {
    selector: "#Youtube",
    content: "Click here to explore Youtube videos.",
  },
  {
    selector: "#LinkedIn",
    content: "Click here to explore LinkedIn posts.",
  },
  {
    selector: "#Ebook",
    content: "View top eBooks as per your keywords.",
  },
  {
    selector: "#outboundai",
    content: "Click here to start outbound AI.",
  },
  // {
  //   selector: "#toTarget",
  //   content: "Click here to find the accounts to target.",
  // },
  {
    selector: "#blogCuration",
    content: "Optimize your blog’s health here.",
  },
  {
    selector: "#resources",
    content: "Click here to find the Templates & Resources for Users.",
  },
];

export const trendingBlogSteps = [
  {
    selector: "#ContentClusterCheckBox",
    content: "Please click on this checkbox to select a trending blog.",
  },
  {
    selector: "#LaunchButton",
    content: "Please click here to read the blog.",
  },
  // {
  //   selector: "#CopyWordCloud",
  //   content: "Please click this button to copy the WordCloud.",
  // },
  // {
  //   selector: "#CopyHeading",
  //   content: "Please click this button to copy a heading.",
  // },
  {
    selector: "#ExpandButton",
    content: "Please Click Expand Icon to view a tree.",
  },
  {
    selector: "#EmailTrendingBlogs",
    content: "Please click on this button to email the Trending Blogs.",
  },
  {
    selector: "#actionBtn",
    content: "Please click on this button to generate AI Blog.",
  },
  // {
  //   selector: "#offpageseo",
  //   content: "Click here to start off-page SEO promotions.",
  // },
];

export const hashSteps = [
  {
    selector: "#CommonCheckBox",
    content: "Click on the checkbox to select a hashtag.",
  },
  {
    selector: "#CopyHashtags",
    content: "Click this button to copy hashtags.",
  },
  {
    selector: "#qasites",
    content: "Click on the Q&A button to view the top questions of Q&A sites.",
  },
];

export const qaSteps = [
  {
    selector: "#CommonCheckBox",
    content: "Please click on any checkbox to select the questions.",
  },
  {
    selector: "#CopyQuora",
    content: "Please click here to copy content from Quora.",
  },
  {
    selector: "#CopyRedditPost",
    content: "Please click here to copy content from Reddit.",
  },
  {
    selector: "#CopyStackOverflow",
    content: "Please click here to copy content from Stack Overflow.",
  },
  {
    selector: "#actionBtn",
    content: "Please click on this button to email QA Questions.",
  },
  {
    selector: "#Social",
    content: "Click here to run your social media campaigns. ",

  },
];

export const youtubeSteps = [
  {
    selector: "#CommonCheckBox",
    content: "Please click the checkboxes to select Youtube video links sites.",
  },
  {
    selector: "#copyYoutubeUrl",
    content: "Please click this button to copy a Youtube links.",
  },
  {
    selector: "#emailBtn",
    content: "Please click this button to send an email.",
  }
];

export const linkedInSteps = [
  {
    selector: "#CommonCheckBox",
    content: "Please click the checkboxes to select LinkedIn posts links sites.",
  },
  {
    selector: "#copyLinkedinUrl",
    content: "Please click this button to copy a Linkedin links.",
  },
  {
    selector: "#emailBtn",
    content: "Please click this button to send an email.",
  }
];

export const ebookSteps = [
  {
    selector: "#CommonCheckBox",
    content: "Please click the checkboxes to select social links sites and click on links to open PDF",
  },
  {
    selector: "#LaunchButton",
    content: "Click here to open the eBooks in new tabs..",
  },
  {
    selector: "#CopyWebsite",
    content: "Please click this button to copy an eBooks links.",
  },
  {
    selector: "#actionBtn",
    content: "Please click this button to send an email.",
  },
  {
    selector: "#outboundai",
    content: "Click here to start outbound AI..",
  },
];

export const outboundAISteps = [
  {
    selector: "#GenerateSubjectLine",
    content: "Please click on this button to generate email subject line.",
  },
  {
    selector: "#CopySubjectLines",
    content: "Please click on this button to copy the email subject lines.",
  },
  // {
  //   selector: "#CopyEmailBody",
  //   content: "Please click here to copy the email body.",
  // },
  // {
  //   selector: "#toTarget",
  //   content: "Click on this button to find relevant accounts to target.",
  // },
];

export const toTargetSteps = [
  {
    selector: "#CommonCheckBox",
    content: "Click on this checkbox to select an account.",
  },
  {
    selector: "#actionBtn",
    content: "Please click on this button to send an email. ",
  },
  {
    selector: "#blogCuration",
    content: "Examine your blog’s health here. ",
  },
];

export const blogCurationSteps = [
  {
    selector: "#input-URL",
    content: "Insert blog link here.",
  },
  {
    selector: "#input-Keyword",
    content: "Insert keyword here.",
  },
  {
    selector: "#resultbutton",
    content: "Please click this button to know the score of your blog.",
  },
  {
    selector: "#tooltips",
    content: "Click here to open the link in new tab.",
  },
  {
    selector: "#score",
    content: "here it will show score of your blog .",
  },
  {
    selector: "#actionBtn",
    content: "Please click on this button to email your analyzed result.",
  },
  {
    selector: "#blogHealth",
    content: "Click below to see the performance of your blog.",
  },
  {
    selector: "#blogHistory",
    content: "Check below blog history as per analyzed blog.",
  },
  {
    selector: "#resources",
    content: "Click here to find the Resources for Users.",
  },
];

export const resourcesSteps = [
  {
    selector: "#CommonCheckBox",
    content: "Please click on any checkbox to select the questions.",
  },
  {
    selector: "#CopyWebsite",
    content: "Please click here to copy website name.",
  },
  {
    selector: "#CopyQuora",
    content: "Please click here to copy content from Buyer Personas.",
  },
  {
    selector: "#CopyRedditPost",
    content: "Please click here to copy content from Learning Sessions.",
  },
  // {
  //   selector: "#CopyStackOverflow",
  //   content: "Please click here to copy content from Sales Documents.",
  // },
  // {
  //   selector: "#actionBtn",
  //   content: "Please click on this button to email Resources details.",
  // },
];