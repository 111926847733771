
import { GET_HISTORY, SET_HISTORY, ERROR_HISTORY } from '../constants/abmHistory';

export const getHistory = (user_id, abmHistory) => ({
    type: GET_HISTORY,
    user_id,
    abmHistory
});

export const setHistory = (abmHistory) => ({
    type: SET_HISTORY,
    abmHistory
});

export const getHistoryFailed = () => ({
    type: ERROR_HISTORY
});
