import { GET_PROMO_REDDIT, SET_PROMO_REDDIT, ERROR_PROMO_REDDIT } from '../constants/promoReddit';

export const getPromoReddit = (id) => ({
    type: GET_PROMO_REDDIT,
    id
});

export const setPromoReddit = (id, reddit, found, showing, authorized) => ({
    type: SET_PROMO_REDDIT,
    id,
    reddit,
    found,
    showing,
    authorized
});

export const getPromoRedditFailed = () => ({
    type: ERROR_PROMO_REDDIT
});
