import * as types from '../constants/targetAccount';

const initialState = {
    id: '',
    targetAccounts: [],
    found: 0,
    showing: 0,
    authorized: false,
    loading: false,
    error: false
};

const targetAccount = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_TARGET_ACC:
            return {
                ...state,
                loading: true,
                error: false
            };

        case types.SET_TARGET_ACC:
            return {
                ...state,
                id: action.id,
                targetAccounts: action.targetAccounts,
                found: action.found,
                showing: action.showing,
                authorized: action.authorized,
                loading: false,
                error: false
            };

        case types.ERROR_TARGET_ACC:
            return {
                ...state,
                id: '',
                targetAccounts: [],
                found: 0,
                showing: 0,
                authorized: false,
                loading: false,
                error: true
            };

        default: return state;
    }
};

export default targetAccount;