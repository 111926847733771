
import { GET_EMAIL_SAMPLE, SET_EMAIL_SAMPLE, ERROR_EMAIL_SAMPLE } from '../constants/genEmailSample';

export const getEmailSample = (id) => ({
    type: GET_EMAIL_SAMPLE,
    id
});

export const setEmailSample = (id, emailSample) => ({
    type: SET_EMAIL_SAMPLE,
    id,
    emailSample
});

export const getEmailSampleFailed = () => ({
    type: ERROR_EMAIL_SAMPLE
});
