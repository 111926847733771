import * as types from '../constants/promoHashTag';

const initialState = {
    id: '',
    loading: false,
    authorized: false,
    error: false,

    usenow: [],
    foundusenow: 0,
    showingusenow: 0,

    overtime: [],
    foundovertime: 0,
    showingovertime: 0,

    neveruse: [],
    foundneveruse: 0,
    showingneveruse: 0,
};

const promoHashTag = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_HASH_TAGS:
            return {
                ...state,
                loading: true,
                error: false
            };

        case types.SET_HASH_TAGS:
            const {
                authorized,

                usenow,
                foundusenow,
                showingusenow,

                overtime,
                foundovertime,
                showingovertime,

                neveruse,
                foundneveruse,
                showingneveruse,
            } = action.response;

            return {
                ...state,
                id: action.id,
                loading: false,
                error: false,
                authorized,

                usenow,
                foundusenow,
                showingusenow,

                overtime,
                foundovertime,
                showingovertime,

                neveruse,
                foundneveruse,
                showingneveruse,
            };

        case types.ERROR_HASH_TAGS:
            return {
                ...state,
                id: '',
                loading: false,
                authorized: false,
                error: true,

                usenow: [],
                foundusenow: 0,
                showingusenow: 0,

                overtime: [],
                foundovertime: 0,
                showingovertime: 0,

                neveruse: [],
                foundneveruse: 0,
                showingneveruse: 0,
            };

        default: return state;
    }
};

export default promoHashTag;