import React, { Fragment } from "react";
import classes from "./AuthButton.module.css";
//import { GoogleLogout } from "react-google-login";
import { withRouter /*,NavLink*/ } from "react-router-dom";
//import DeliveryIco from './../Icons/DeliveryIco';
import LogoutIco from "components/Icons/LogoutIco";

const AuthButton = function({ onGoogleLoggout, profileObj, location, onSignOut }) {
  //const inputEl = useRef(null);

  return (
    <div className={classes.signedInBox}>
      <div className={classes.signedInButton}>
        {profileObj.imageUrl ? (
          <img src={profileObj.imageUrl} alt={profileObj.givenName} className={classes.avatar} />
        ) : profileObj.givenName && profileObj.familyName ? (
          profileObj.givenName.split("")[0] + profileObj.familyName.split("")[0]
        ) : (
          profileObj.email.split("")[0].toUpperCase()
        )}
      </div>
      <div className={classes.signedDiv}>
        <div onClick={onSignOut} className={classes.logOutButton}>
          <Fragment>
            <LogoutIco className={classes.logOutIco} />
            <span>Logout</span>
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default withRouter(AuthButton);
