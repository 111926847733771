import * as types from '../constants/auth';

const initialState = {
    appAuthChecked: false,
    loginSuccess: false,
    loginInProcess: false,
    response: null
};

const auth = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {

        case types.LOCAL_AUTH_COMPLETED:
            return {
                ...state,
                appAuthChecked: true
            };

        case types.START_LOGIN:
            return {
                ...state,
                loginSuccess: false,
                loginInProcess: true,
                response: null
            };

        case types.LOGIN_SUCCESS:
            return {
                ...state,
                loginSuccess: true,
                loginInProcess: false,
                response: action.response
            };

        case types.LOGIN_FAILED:
        case types.POST_LOGOUT:
            return {
                ...state,
                loginSuccess: false,
                loginInProcess: false,
                response: null
            };

        default: return state;
    }
};

export default auth;