import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

function Loader({ active }) {
  return (
    active && (
      <div style={{ position: "absolute", top: "50%", left: "50%" }}>
        <CircularProgress style={{ color: "#61788B" }} />
      </div>
    )
  );
}

export default React.memo(Loader);
