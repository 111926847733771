import { primaryColor } from "assets/jss/material-dashboard-react";
import * as React from "react";

function TrendingBlogsSVG(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={40} height={35.999} viewBox="0 0 40 35.999" {...props}>
      <path
        data-name="Icon awesome-blog"
        d="M13.455 15.949a1.828 1.828 0 00-2.2 1.673v3.544a1.725 1.725 0 001.3 1.547A3.423 3.423 0 0115 25.877a3.579 3.579 0 01-3.751 3.375A3.579 3.579 0 017.5 25.877V8.44a1.785 1.785 0 00-1.874-1.688H1.875A1.785 1.785 0 000 8.44v17.437c0 6.293 6.415 11.264 13.674 9.893a10.706 10.706 0 008.572-7.713c1.36-5.829-2.891-11.053-8.79-12.108zM16.331.002A1.209 1.209 0 0015 1.127v2.222a1.172 1.172 0 001.172 1.118c10.113.492 18.239 7.875 18.828 16.98a1.2 1.2 0 001.24 1.055h2.508a1.191 1.191 0 001.252-1.2C39.334 9.832 29.082.602 16.331.002zm.023 6.75A1.213 1.213 0 0015 7.884v2.257a1.178 1.178 0 001.156 1.118c6 .443 10.783 4.8 11.322 10.209a1.211 1.211 0 001.245 1.034h2.516a1.2 1.2 0 001.261-1.216c-.656-7.741-7.54-13.936-16.143-14.534z"
        fill={props && props.className.includes("activeIcon") ? primaryColor[5] : "#a0a0a0"}
      />
    </svg>
  );
}

export default TrendingBlogsSVG;
