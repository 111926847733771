import React, { useCallback, useState, useEffect, Fragment } from 'react';
import Fade from '@material-ui/core/Fade';
import classes from './Payment.module.css';
import logo from '../../assets/img/marxeed_logo.png';
import { connect } from 'react-redux';
import { postKeywordDetails } from '../../redux/actions/search';
import { openAlert } from './../../redux/actions/alert';
import { validateEmail, validateObject } from '../../utils/helper';
import { createPaymentIntent } from 'utils/paymentIntent';
import Button from '@material-ui/core/Button';
import StripeCard from './StripeCard';
import CircularProgress from '@material-ui/core/CircularProgress';


function Payment({
    open,
    onClose,
    email,
    keywords,
    location,
    company,
    isFree,
    postKeywordDetails,
    openAlert
}) {

    const [inputs, setInputs] = useState({
        email,
        keywords,
        location,
        company,
        name: '',
        lineOne: '',
        lineTwo: '',
        city: '',
        state: ''
    });

    const [touched, setTouched] = useState({
        email: false,
        keywords: false,
        location: false,
        company: false
    });

    const [payDisabled, setPayDisabled] = useState(true);
    const [publicKey, setPublicKey] = useState('');
    const [apiLoading, setApiLoading] = useState(false)

    useEffect(() => {
        setInputs(pervState => ({
            ...pervState,
            email,
            keywords,
            location,
            company
        }));
    }, [email, keywords, location, company, setInputs]);

    const onInputChange = useCallback((event, key) => {
        const value = event.target.value;

        setInputs(pervState => ({
            ...pervState,
            [key]: value
        }));
    }, [setInputs]);

    const onInputTouched = useCallback((key) => {
        setTouched(pervState => ({
            ...pervState,
            [key]: true
        }));
    }, [setTouched]);

    useEffect(() => {
        let payDisabled = false;
        if (isFree) {
            payDisabled = !inputs.keywords || !inputs.company || !validateEmail(inputs.email);
        } else {
            payDisabled = !validateObject(inputs) || !validateEmail(inputs.email);
        }
        setPayDisabled(payDisabled);
    }, [setPayDisabled, inputs, isFree]);

    const payClick = useCallback(async (event) => {
        try {
            event.preventDefault();
            event.stopPropagation();
            setApiLoading(true);

            if (isFree) {
                event.preventDefault();
                event.stopPropagation();
                postKeywordDetails(inputs.email, inputs.keywords, inputs.location, inputs.company);
                onClose();
            } else {
                const orderData = {
                    items: [{ id: "marxeed-report" }],
                    currency: "usd",
                    description: inputs.keywords,
                    shipping: [{
                        name: inputs.name, address: {
                            city: inputs.city,
                            country: inputs.location,
                            line1: inputs.lineOne,
                            line2: inputs.lineTwo,
                            state: inputs.state
                        }
                    }]
                };

                const response = await createPaymentIntent(orderData);
                setPublicKey(response.publishableKey);
            }
            setApiLoading(false);
        } catch (error) {
            setApiLoading(false);
            //console.log('Error in payClick function', error);
        }
    }, [isFree, inputs, postKeywordDetails, setPublicKey, setApiLoading, onClose]);

    const onPayCompleted = useCallback((orderId) => {
        if (orderId) {
            openAlert('Thank you!', 'Thank you for choosing MarXeed. We have received your payment and we will share your sample report in the next 24 hours.')
            onClose();
        }
    }, [openAlert, onClose]);

    const onPayError = useCallback((error) => {
        //console.log('On Pay Error', error);
        openAlert('Something went wrong!',
            'We regret to inform you that your payment verification has failed. If any amount is debited, it will be refunded by your bank within 5 working days.');
    }, [openAlert]);

    return open ? <div>
        <Fade in={open}>
            <div className={classes.modalWrapper} onClick={onClose}>
                <div id="pay" className={classes.payContainer} onClick={event => event.stopPropagation()}>
                    <div className={classes.closeModal} onClick={onClose}>x</div>
                    <h2 className={classes.payHeader}>
                        <img alt="Marxeed Logo" src={logo} />
                    </h2>
                    <div>
                        Your "{inputs.keywords}" report for "{inputs.location}"
                    </div>
                    {
                        !isFree &&
                        <div className={classes.payInfo}>
                            Which gives access to detailed marketing content straight to your mailbox
                         </div>
                    }
                    <div className={classes.payBox}>
                        {!publicKey ? <form onSubmit={payClick}>
                            <table>
                                <tbody>
                                    <tr><td className={classes.displayNone} ><input type="hidden" name="on0" value="Marxeed" />marxeed</td></tr>
                                    <tr className={classes.displayNone}><td><select>
                                        <option value="Report">Report $10.00 USD</option>
                                    </select> </td></tr>


                                    <tr><td><input type="hidden" value="Name" />Company Name*</td></tr>
                                    <tr><td><input type="text" maxLength="200" placeholder="Your company logo will appear in the report"
                                        value={inputs.company} onChange={(e) => onInputChange(e, 'company')}
                                        onBlur={() => onInputTouched('company')} />
                                        {(!inputs.company && touched.company) && <div className={classes.invalid}>Please enter company name.</div>}
                                    </td></tr>

                                    <tr><td><input type="hidden" value="Email ID" />Where to send your report?*</td></tr>
                                    <tr><td>
                                        <input type="email" maxLength="200" placeholder="Your Business Email Address*"
                                            value={inputs.email} onChange={(e) => onInputChange(e, 'email')}
                                            onBlur={() => onInputTouched('email')} />
                                        {(!validateEmail(inputs.email) && touched.email) && <div className={classes.invalid}>Please enter valid email address.</div>}
                                    </td></tr>

                                    {!isFree && <Fragment>
                                        <tr><td><input type="hidden" value="Name" />Name*</td></tr>
                                        <tr><td>
                                            <input type="text" maxLength="200" placeholder="Your Name*"
                                                value={inputs.name} onChange={(e) => onInputChange(e, 'name')}
                                                onBlur={() => onInputTouched('name')} />
                                            {(!inputs.name && touched.name) && <div className={classes.invalid}>Please enter a You name.</div>}
                                        </td></tr>


                                        <tr><td><input type="hidden" value="Address" />Address*</td></tr>
                                        <tr><td>
                                            <input type="text" maxLength="200" placeholder="Line 1*"
                                                value={inputs.lineOne} onChange={(e) => onInputChange(e, 'lineOne')}
                                                onBlur={() => onInputTouched('lineOne')} />
                                            {(!inputs.lineOne && touched.lineOne) && <div className={classes.invalid}>Please enter address Line 1.</div>}
                                        </td></tr>
                                        <tr><td>
                                            <input type="text" maxLength="200" placeholder="Line 2*"
                                                value={inputs.lineTwo} onChange={(e) => onInputChange(e, 'lineTwo')}
                                                onBlur={() => onInputTouched('lineTwo')} />
                                            {(!inputs.lineTwo && touched.lineTwo) && <div className={classes.invalid}>Please enter address Line 2.</div>}
                                        </td></tr>
                                        <tr>
                                            <td className={classes.triInput}>
                                                <div>
                                                    <input type="text" maxLength="200" placeholder="City*"
                                                        value={inputs.city} onChange={(e) => onInputChange(e, 'city')}
                                                        onBlur={() => onInputTouched('city')} />
                                                    {(!inputs.city && touched.city) && <div className={classes.invalid}>Please enter City.</div>}
                                                </div>

                                                <div>
                                                    <input type="text" maxLength="200" placeholder="State*"
                                                        value={inputs.state} onChange={(e) => onInputChange(e, 'state')}
                                                        onBlur={() => onInputTouched('state')} />
                                                    {(!inputs.state && touched.state) && <div className={classes.invalid}>Please enter State.</div>}
                                                </div>

                                                <div>
                                                    <input id="input" type="text" maxLength="200" placeholder="Country*"
                                                        value={inputs.location} onChange={(e) => onInputChange(e, 'location')}
                                                        onBlur={() => onInputTouched('location')} />
                                                    {(!inputs.location && touched.location) && <div className={classes.invalid}>Please enter Country.</div>}
                                                </div>
                                            </td>
                                        </tr>
                                    </Fragment>}


                                </tbody>
                            </table>
                            <div>
                                {
                                    isFree ?
                                        "" :
                                        "Introductory offer @ $29 instead of $59"
                                }
                            </div>
                            {
                                apiLoading ? <CircularProgress
                                    classes={{ colorPrimary: classes.colorPrimary }} /> :
                                    <Button classes={{ root: classes.submitBtn }} disabled={payDisabled} type="button" onClick={payClick}>{
                                        isFree ? 'Send Report' : 'Submit'
                                    }</Button>
                            }
                        </form> : <StripeCard publicKey={publicKey} onPayCompleted={onPayCompleted} onPayError={onPayError} />}
                    </div>
                </div>
            </div>
        </Fade>
    </div> : '';
}

const mapStateToProps = ({
    search,
    auth
}) => ({
    ...search,
    email: auth.response ? auth.response.profileObj.email : ''
});

const mapDispatchToProps = { postKeywordDetails, openAlert };

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
