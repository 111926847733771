import { START_LOGIN, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT, POST_LOGIN, LOCAL_AUTH_CHECK, LOCAL_AUTH_COMPLETED, POST_LOGOUT, CALL_CHECKPOINT } from '../constants/auth';

export const onStartLogin = (email, password) => ({
    type: START_LOGIN,
    email,
    password
});

export const onPostLogin = () => ({
    type: POST_LOGIN
});

export const onLoginSuccess = (response) => ({
    type: LOGIN_SUCCESS,
    response
});

export const onLoginFailed = (response) => ({
    type: LOGIN_FAILED,
    response
});

export const onLoggout = () => ({
    type: LOGOUT
});

export const onPostLoggout = () => ({
    type: POST_LOGOUT
});


export const onCallCheckPoint = (id) => ({
    type: CALL_CHECKPOINT,
    id
});

export const onLocalAuthCheck = () => ({
    type: LOCAL_AUTH_CHECK
});

export const onLocalAuthCompleted = () => ({
    type: LOCAL_AUTH_COMPLETED
});

