import { all } from 'redux-saga/effects';
import authSaga from './auth';
import getData from './getData';
import getInboundData from './inbound';
import getPromoData from './promo';
import watchPollStatusSaga from './status';
import trendingBlog from './trendingBlog';

export default function* rootSaga() {


    yield all([
        // Sagas go here
        authSaga(),
        getData(),
        getInboundData(),
        getPromoData(),
        watchPollStatusSaga(),
        trendingBlog()
    ]);
}