import * as types from '../constants/alert';

const initialState = {
    open: false,
    title: '',
    info: ''
};

const alert = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.OPEN_ALERT:
            return {
                ...state,
                open: true,
                title: action.title,
                info: action.info
            };

        case types.CLOSE_ALERT:
            return {
                ...state,
                open: false,    
                title: '',
                info: ''
            };

        default: return state;
    }
};

export default alert;