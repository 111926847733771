import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

const headerStyle = () => ({
  appBar: {
    backgroundColor: "#61788B",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    position: "absolute",
    width: "100%",
    //paddingTop: "10px",
    zIndex: "1029",
    color: grayColor[7],
    border: "0",
    // borderRadius: "3px",
    padding: "1px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
  },
  container: {
    ...container,
    minHeight: "50px",
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#fff !important",
    width: "100%",
  },
  title: {
    ...defaultFont,
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
  appResponsive: {
    top: "8px",
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  logoContainer: {
    marginTop: 10,
    marginLeft: 12,
    marginRight: 60,
  },
  logo: {
    height: "46px",
    objectFix: "cover",
  },
  notificationContainer: { alignItems: "center", display: "flex", width: "fit-content", marginRight: 25, cursor: "pointer" },
  notificationCount: {
    position: "absolute",
    top: 0,
    marginLeft: 23,
    marginTop: 15,
    height: 15,
    width: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
    backgroundColor: "#F74C54",
  },
});

export default headerStyle;
