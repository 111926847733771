import React, { useState, useEffect } from 'react';
import classes from './Payment.module.css'
import { loadStripe } from '@stripe/stripe-js';

import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

let stripePromise = null;
const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            fontSize: '18px',
            fontFamily: '"Open Sans", sans-serif',
            fontSmoothing: 'antialiased',
            '::placeholder': {
                color: '#CFD7DF',
            },
            border: '1px solid #eee',
            borderRadius: '4px',
            boxSizing: 'border-box',
            color: '#0202029D',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 3px 6px #00000029'

        },
        invalid: {
            color: '#e5424d',
            ':focus': {
                color: '#303238',
            },
        },
    },
};

const CheckOutForm = ({
    onPayCompleted,
    onPayError
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [apiLoading, setApiLoading] = useState(false)

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setApiLoading(true);
            const { paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });

            setApiLoading(false);
            onPayCompleted(paymentMethod.id);
        } catch (e) {
            setApiLoading(false);
            onPayError(e);
            //console.log('CheckOutForm funtion error', e);
        }
    };
    return <form onSubmit={handleSubmit} >
        <div className={classes.checkoutFormLabel}>Card Details</div>
        <div className={classes.checkoutFormContainer}>
            <CardElement options={CARD_OPTIONS} />
        </div>
        {
            apiLoading ? <CircularProgress
                classes={{ colorPrimary: classes.colorPrimary }} /> :
                <Button classes={{ root: classes.submitBtn }} type="submit" disabled={!stripe}>Pay</Button>
        }
    </form >
}

const StripeCard = ({
    publicKey,
    onPayCompleted,
    onPayError
}) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        stripePromise = loadStripe(publicKey);
        setShow(true);
    }, [setShow, publicKey])

    return show ? <Elements stripe={stripePromise}>
        <CheckOutForm onPayCompleted={onPayCompleted} onPayError={onPayError} />
    </Elements> : null

};

export default StripeCard;