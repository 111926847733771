import * as types from '../constants/snackbar';

const initialState = {
    open: false,
    message: "",
    // place: "",
    severity: "success",
    path: ""
};

const setSnackbar = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.OPEN_SNACKBAR:
            return {
                ...state,
                open: action.open,
                message: action.message,
                // place: action.place,
                severity: action.severity,
                path: action.path
            };

        case types.CLOSE_SNACKBAR:
            return {
                ...state,
                open: action.open,
                message: action.message,
                // place: "",
                severity: action.severity,
                path: action.path
            };

        default: return state;
    }
};

export default setSnackbar;