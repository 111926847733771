import * as types from '../constants/promoQuora';

const initialState = {
    id: '',
    quora: [],
    found: 0,
    showing: 0,
    authorized: false,
    loading: false,
    error: false
};

const promoQuora = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_PROMO_QUORA:
            return {
                ...state,
                loading: true,
                error: false
            };

        case types.SET_PROMO_QUORA:
            return {
                ...state,
                id: action.id,
                quora: action.quora,
                found: action.found,
                showing: action.showing,
                authorized: action.authorized,
                loading: false,
                error: false
            };

        case types.ERROR_PROMO_QUORA:
            return {
                ...state,
                id: '',
                quora: [],
                found: 0,
                showing: 0,
                authorized: false,
                loading: false,
                error: true
            };

        default: return state;
    }
};

export default promoQuora;