import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";
//import styles from "assets/jss/material-dashboard-react/components/snackbarContentStyle.js";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import { closeSnackbar } from "../../redux/actions/snackbar";
import { connect } from "react-redux";

//const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Notification(props) {
  //const classes = useStyles();
  const {
    message,
    severity,
    //close,
    //icon,
    //place,
    open,
    closeSnackbar,
    path
  } = props;

  const closeNotification = () => {
    closeSnackbar();
  };
  return (
    <Snackbar anchorOrigin={{ horizontal: "right", vertical: "top" }} open={open} onClose={closeNotification} autoHideDuration={5000}>
      <Alert onClose={closeNotification} severity={severity}>
        <div style={{ marginTop: -5 }}>
          <h4 style={{ margin: 0, fontWeight: "500" }}>{severity?.charAt(0).toUpperCase() + severity?.slice(1, severity?.length)}</h4>
          <p style={{ margin: 0, fontWeight: "400" }}>{message}</p>
          {path ? <a href={path} target="_blank">Link</a> : null}
        </div>
      </Alert>
    </Snackbar>
  );
}

Notification.propTypes = {
  message: PropTypes.node.isRequired,
  severity: PropTypes.oneOf(["info", "success", "warning", "error"]),
  open: PropTypes.bool,
  path: PropTypes.string
};

const mapDispatchToProps = { closeSnackbar };

export default connect(
  null,
  mapDispatchToProps
)(Notification);
