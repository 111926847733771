import { primaryColor } from "assets/jss/material-dashboard-react";
import * as React from "react";

function ContentClusterSVG(props) {
  return (
    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="3 0 25 30.999" width={40} height={30} {...props}>
      <path
        data-name="Icon awesome-blogger-b"
        d="M 6 4 C 4.895 4 4 4.895 4 6 L 4 24 C 4 25.105 4.895 26 6 26 L 24 26 C 25.105 26 26 25.105 26 24 L 26 6 C 26 4.895 25.105 4 24 4 L 6 4 z M 12.308594 8 L 15 8 C 17.38 8 19.325594 9.9567969 19.308594 12.341797 C 19.303594 12.923797 19.802766 13.384766 20.384766 13.384766 L 20.923828 13.384766 C 21.518828 13.384766 22 13.867891 22 14.462891 L 22 17.691406 C 22 20.061406 20.061406 22 17.691406 22 L 12.306641 22 C 9.9376406 22 8 20.061406 8 17.691406 L 8 15 L 8 12.308594 C 8 9.9385938 9.9385938 8 12.308594 8 z M 12.308594 11.230469 C 11.716594 11.230469 11.230469 11.716594 11.230469 12.308594 C 11.230469 12.900594 11.716594 13.384766 12.308594 13.384766 L 15 13.384766 C 15.592 13.384766 16.076172 12.900594 16.076172 12.308594 C 16.076172 11.716594 15.592 11.230469 15 11.230469 L 12.308594 11.230469 z M 12.306641 16.615234 C 11.714641 16.615234 11.230469 17.099406 11.230469 17.691406 C 11.230469 18.283406 11.714641 18.769531 12.306641 18.769531 L 17.691406 18.769531 C 18.283406 18.769531 18.769531 18.283406 18.769531 17.691406 C 18.769531 17.099406 18.284406 16.615234 17.691406 16.615234 L 12.306641 16.615234 z"
        fill={props && props.className.includes("activeIcon") ? primaryColor[5] : "#a0a0a0"}
      />
    </svg>
  );
}

export default ContentClusterSVG;