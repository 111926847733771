import * as types from '../constants/abmHistory';

const initialState = {
    id:'',
    user_id: '',
    abmHistory: [],
    loading: false,
    error: false
};

const abmHistory = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_HISTORY:
            return {
                ...state,
                id: action.id,
                user_id: action.id,
                abmHistory: action.abmHistory,
                loading: true,
                error: false
            };

        case types.SET_HISTORY:
            return {
                ...state,
                id: action.id,
                abmHistory: action.abmHistory ,
                loading: false,
                error: false
            };

        case types.ERROR_HISTORY:
            return {
                ...state,
                id: '',
                abmHistory: [],
                error: true,
                authorized: false
            };

        default: return state;
    }
};

export default abmHistory;