import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from '../constants/snackbar';

export const openSnackbar = (payload) => ({
    type: OPEN_SNACKBAR,
    open: payload.open,
    message: payload.message,
    severity: payload.severity,
    path: payload.path,
});

export const closeSnackbar = () => ({
    type: CLOSE_SNACKBAR,
    open: false,
    message: "",
    severity: "",
    path: ""
})
