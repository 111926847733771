import * as types from '../constants/trendingBlogs';

const initialState = {
    id: '',
    trendingBlogs: [],
    found: 0,
    showing: 0,
    authorized: false,
    loading: false,
    error: false,
};

const trendingBlogs = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_TRENDING_BLOGS:
            return {
                ...state,
                loading: true,
                error: false
            };

        case types.SET_TRENDING_BLOGS:
            return {
                ...state,
                id: action.id,
                trendingBlogs: action.trendingBlogs,
                found: action.found,
                showing: action.showing,
                authorized: action.authorized,
                loading: false,
                error: false
            };

        case types.ERROR_TRENDING_BLOGS:
            return {
                ...state,
                id: '',
                trendingBlogs: [],
                found: 0,
                showing: 0,
                loading: false,
                error: true,
                authorized: false
            };

        default: return state;
    }
};
export default trendingBlogs;