import React from 'react'
import classObj from './SidebarItem.module.css';

function SidebarItem() {
  return (
    <sub className={classObj.animateGradientText}>
        AI
    </sub>
  )
}

export default SidebarItem