import * as types from '../constants/mindMapTitle';

const initialState = {
    id: '',
    cclTitlesMindmap: [],
    found: 0,
    showing: 0,
    authorized: false,
    loading: false,
    error: false
};

const mindMapTitle = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_CCL_TITLES:
            return {
                ...state,
                loading: true,
                error: false,
                id: action.id
            };

        case types.SET_CCL_TITLES:
           return {
                ...state,
                id: action.id,
                cclTitlesMindmap: action.cclTitlesMindmap,
                found: action.found,
                showing: action.showing,
                authorized: action.authorized,
                loading: false,
                error: false
            };

        case types.ERROR_CCL_TITLES:
            return {
                ...state,
                id: '',
                cclTitlesMindmap: [],
                found: 0,
                showing: 0,
                loading: false,
                error: true,
                authorized: false
            };

        default: return state;
    }
};

export default mindMapTitle;