import { primaryColor } from "assets/jss/material-dashboard-react";
import * as React from "react";

function PromotionSVG(props) {
  return (
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"  width={25.503} height={30.225} viewBox="0 0 227.216 227.216" {...props} >
<path d="M175.897,141.476c-13.249,0-25.11,6.044-32.98,15.518l-51.194-29.066c1.592-4.48,2.467-9.297,2.467-14.317
	c0-5.019-0.875-9.836-2.467-14.316l51.19-29.073c7.869,9.477,19.732,15.523,32.982,15.523c23.634,0,42.862-19.235,42.862-42.879
	C218.759,19.229,199.531,0,175.897,0C152.26,0,133.03,19.229,133.03,42.865c0,5.02,0.874,9.838,2.467,14.319L84.304,86.258
	c-7.869-9.472-19.729-15.514-32.975-15.514c-23.64,0-42.873,19.229-42.873,42.866c0,23.636,19.233,42.865,42.873,42.865
	c13.246,0,25.105-6.042,32.974-15.513l51.194,29.067c-1.593,4.481-2.468,9.3-2.468,14.321c0,23.636,19.23,42.865,42.867,42.865
	c23.634,0,42.862-19.23,42.862-42.865C218.759,160.71,199.531,141.476,175.897,141.476z M175.897,15
	c15.363,0,27.862,12.5,27.862,27.865c0,15.373-12.499,27.879-27.862,27.879c-15.366,0-27.867-12.506-27.867-27.879
	C148.03,27.5,160.531,15,175.897,15z M51.33,141.476c-15.369,0-27.873-12.501-27.873-27.865c0-15.366,12.504-27.866,27.873-27.866
	c15.363,0,27.861,12.5,27.861,27.866C79.191,128.975,66.692,141.476,51.33,141.476z M175.897,212.216
	c-15.366,0-27.867-12.501-27.867-27.865c0-15.37,12.501-27.875,27.867-27.875c15.363,0,27.862,12.505,27.862,27.875
	C203.759,199.715,191.26,212.216,175.897,212.216z"
  fill={props && props.className.includes("activeIco") ? primaryColor[5] : "#a0a0a0"}
      />
</svg>


  //   <svg xmlns="http://www.w3.org/2000/svg" width={18.503} height={20.225} viewBox="0 0 32.277254 36.241271" {...props}  >
  //   <path
  //       id="Icon_awesome-share-alt"
  //       data-name="Icon awesome-share-alt"
  //       d="m 25.358,22.402272 a 7.01,7.01 0 0 0 -4.307,1.463 l -7.383,-4.485 a 6.579,6.579 0 0 0 0,-2.919 l 7.383,-4.486 a 7.01,7.01 0 0 0 4.307,1.464 6.822,6.822 0 0 0 6.916,-6.7239998 6.919,6.919 0 0 0 -13.832,0 6.565,6.565 0 0 0 0.164,1.46 l -7.383,4.4849998 a 7.01,7.01 0 0 0 -4.307,-1.463 6.822,6.822 0 0 0 -6.916,6.723 6.822,6.822 0 0 0 6.916,6.724 7.01,7.01 0 0 0 4.307,-1.463 l 7.383,4.486 a 6.565,6.565 0 0 0 -0.164,1.46 6.919,6.919 0 1 0 6.916,-6.724 z"
  //       fill={props && props.className.includes("activeIco") ? primaryColor[5] : "#a0a0a0"}  />
  //  </svg>

    
    // <svg xmlns="http://www.w3.org/2000/svg" width={18.503} height={20.225} viewBox="0 0 18.503 20.225" {...props}>
    //   <g
    //     data-name="Icon feather-share-2"
    //     stroke="#a0a0a0"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //     strokeWidth={1.4}
    //     fill={props && props.className.includes("activeIco") ? primaryColor[5] : "#a0a0a0"}>
    //     <path data-name="Path 1" d="M17.003 4.084A2.584 2.584 0 1114.419 1.5a2.584 2.584 0 012.584 2.584z" />
    //     <path data-name="Path 2" d="M6.668 10.113a2.584 2.584 0 11-2.584-2.584 2.584 2.584 0 012.584 2.584z" />
    //     <path data-name="Path 3" d="M17.003 16.142a2.584 2.584 0 11-2.584-2.584 2.584 2.584 0 012.584 2.584z" />
    //     <path data-name="Path 4" d="M6.315 11.413l5.882 3.428" />
    //     <path data-name="Path 5" d="M12.189 5.384L6.315 8.812" />
    //   </g>
    // </svg>
  );
}

export default PromotionSVG;
