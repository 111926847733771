import * as types from "../constants/youtubeContent";

const initialState = {
  videosList: [],
}

const youtubeContent = (state = initialState, { type, ...action }) => {
  switch (type) {
    case types.SET_VIDEOS_LIST:
      return {
        ...state,
        videosList: action.response || []
      }

    default:
      return state;
  }
}

export default youtubeContent;