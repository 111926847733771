import React from 'react';
import { connect } from 'react-redux';
import classes from './AlertModal.module.css';
import logo from '../../assets/img/marxeed_logo_tag.png';
import { closeAlert } from '../../redux/actions/alert';

const AlertModal = function ({
    open,
    closeAlert,
    title,
    info
}) {
    return open ? <div>
        <div className={classes.modalWrapper} onClick={closeAlert}>
            <div id="alert" className={classes.container} onClick={event => event.stopPropagation()}>
                <div className={classes.closeModal} onClick={closeAlert}>x</div>

                <header className={classes.header}>
                    <img className={classes.logoImg} alt="Marxeed Logo" src={logo} />
                </header>
                <div className={classes.title}>{title}</div>
                <div className={classes.info}>{info}</div>
            </div>
        </div>
    </div> : null
};
const mapStateToProps = ({
    alert
}) => ({
    ...alert
});

const mapDispatchToProps = { closeAlert };

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);