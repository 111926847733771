import * as types from '../constants/contentCluster';

const initialState = {
    id: '',
    clusterKeywords: {
        list: [],
        found: 0,
        showing: 0,
        authorized: false,
        loading: false,
        error: false
    },
    clusterTiles: {
        list: [],
        found: 0,
        showing: 0,
        authorized: false,
        loading: false,
        error: false
    }
};

const contentCluster = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {

        // To handle Content CLuster Keywords State
        case types.GET_CLUSTER_KEYWORDS:
            return {
                ...state,
                clusterKeywords: {
                    ...state.clusterKeywords,
                    loading: true,
                    error: false
                }
            };

        case types.SET_CLUSTER_KEYWORDS:
            return {
                ...state,
                id: action.id,
                clusterKeywords: {
                    ...state.clusterKeywords,
                    list: action.clusterKeywords,
                    found: action.found,
                    showing: action.showing,
                    authorized: action.authorized,
                    loading: false,
                    error: false
                }
            };

        case types.ERROR_CLUSTER_KEYWORDS:
            return {
                ...state,
                id: '',
                clusterKeywords: {
                    ...state.clusterKeywords,
                    list: [],
                    found: 0,
                    showing: 0,
                    authorized: false,
                    loading: false,
                    error: true
                }
            };


        // To handle Content CLuster Tiles State
        case types.GET_CLUSTER_TILES:
            return {
                ...state,
                clusterTiles: {
                    ...state.clusterTiles,
                    loading: true,
                    error: false
                }
            };

        case types.SET_CLUSTER_TILES:
            return {
                ...state,
                id: action.id,
                clusterTiles: {
                    ...state.clusterTiles,
                    list: action.clusterTiles,
                    found: action.found,
                    showing: action.showing,
                    authorized: action.authorized,
                    loading: false,
                    error: false
                }
            };

        case types.ERROR_CLUSTER_TILES:
            return {
                ...state,
                id: '',
                clusterTiles: {
                    ...state.clusterTiles,
                    list: [],
                    found: 0,
                    showing: 0,
                    authorized: false,
                    loading: false,
                    error: true
                }
            };

        default: return state;
    }
};

export default contentCluster;