
import { GET_SEARCH, SET_SEARCH, ERROR_SEARCH, POST_SEARCH } from '../constants/search';

export const getSearchDetails = (id) => ({
    type: GET_SEARCH,
    id
});

export const setSearchDetails = (
    id,
    email,
    keywords,
    location,
    company,
    name) => ({
        type: SET_SEARCH,
        id,
        email,
        keywords,
        location,
        company,
        name
    });

export const getSearchDetailsFailed = () => ({
    type: ERROR_SEARCH
});

export const postKeywordDetails = (email, keywords, location, company) => ({
    type: POST_SEARCH,
    email, keywords, location, company
});