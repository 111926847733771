import axios from "axios";

const BACKEND_BASE_URL = process.env.REACT_APP_API_URL;
const OPENAI_BASE_URL = process.env.REACT_APP_OPENAI_API_BASE_URL;

// axios instance for marxeed backend api calls
export const axiosBackendInstance = axios.create({
  baseURL: BACKEND_BASE_URL,
});

// axios instance for open_ai api calls
export const axiosInstance = axios.create({
  baseURL: OPENAI_BASE_URL,
});
