import * as types from '../constants/genEmailSample';

const initialState = {
    id: '',
    emailSample: '',
    loading: false,
    error: false
};

const genEmailSample = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_EMAIL_SAMPLE:
            return {
                ...state,
                loading: true,
                error: false
            };

        case types.SET_EMAIL_SAMPLE:
            return {
                ...state,
                id: action.id,
                emailSample: action.emailSample,
                loading: false,
                error: false
            };

        case types.ERROR_EMAIL_SAMPLE:
            return {
                ...state,
                id: '',
                emailSample: '',
                loading: false,
                error: true
            };

        default: return state;
    }
};

export default genEmailSample;