const awsMarxeed = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: window.location.hostname === "localhost" ? `http://localhost:8080` : process.env.REACT_APP_REDIRECT_URL,
      redirectSignOut: window.location.hostname === "localhost" ? `http://localhost:8080` : process.env.REACT_APP_REDIRECT_URL,
      responseType: "code",
    },
  },
};

export default awsMarxeed;
