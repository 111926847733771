import { axiosBackendInstance } from "api/axios/axios";

async function callApi(url, method, headers, body) {
  try {
    const options = {
      method,
      url: url,
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      mode: "cors",
    };

    if (process.env.REACT_APP_X_API_KEY) {
      options.headers = {
        ...options.headers,
        "x-api-key": process.env.REACT_APP_API_KEY,
      };
    }

    if (method === "POST" || method === "PUT") {
      options.data = JSON.stringify(body);
    }

    const response = await axiosBackendInstance(options);

    if (response?.status !== 200) {
      //console.log("Error Details url", url);
      throw Error(`Error Occured in ${method} ${url} API`);
    }
    return await response?.data;
  } catch (error) {
    //console.log("Error Details url", url);
    throw Error(`Error Occured in ${method} ${url} API`);
  }
}

export const POST = (url, headers, body) => callApi(url, "POST", headers, body);
export const GET = (url, headers) => callApi(url, "GET", headers);
