import * as types from "../constants/promoLinkedin";

const initialState = {
  linkedin: [],
  loading: false,
  error: false,
};

const promoLinkedin = (state = initialState, { type, ...action }) => {
  switch (type) {
    case types.GET_PROMO_LINKEDIN:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case types.SET_PROMO_LINKEDIN:
      return {
        ...state,
        linkedin: action.linkedin,
        loading: false,
        error: false,
      };

    case types.ERROR_PROMO_LINKEDIN:
      return {
        ...state,
        linkedin: [],
        loading: false,
        error: true,
      };

    default:
      return state;
  }
};

export default promoLinkedin;
