import * as types from '../constants/abmReport';

const initialState = {
    id: '',
    link:'',
    loading: false,
    error: false
};

const abmReport = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {

        case types.GET_REPORT:
            return {
                ...state,
                loading: true,
                error: false
            };

        case types.GET_REPORT_ID:
            return {
                ...state,
                loading: {[action.id]:true},
                error: false
            };

        case types.SET_REPORT:
            return {
                ...state,
                link: {[action.data.id]:action.data.URL},
                loading: {[action.data.id]:false},
                error: false
            };

        case types.ERROR_REPORT:
            return {
                ...state,
                error: true,
                authorized: false
            };

        default: return state;
    }
};

export default abmReport;