import { GET_CLOUD_WORDS, SET_CLOUD_WORDS, ERROR_CLOUD_WORDS } from "./../constants/wordCloud";

export const getCloudWords = (url) => ({
  type: GET_CLOUD_WORDS,
  url,
});

export const setCloudWords = (url, cloudWords, authorized) => ({
  type: SET_CLOUD_WORDS,
  url,
  cloudWords,
  authorized,
});

export const getCloudWordsFailed = () => ({
  type: ERROR_CLOUD_WORDS,
});
