import * as types from '../constants/promoEbook';

const initialState = {
    id: '',
    ebook: [],
    showing: 0,
    authorized: false,
    loading: false,
    error: false
};

const promoEbook = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_PROMO_EBOOK:
            return {
                ...state,
                loading: true,
                error: false
            };

        case types.SET_PROMO_EBOOK:
            return {
                ...state,
                id: action.id,
                ebook: action.ebook,
                showing: action.showing,
                authorized: action.authorized,
                loading: false,
                error: false
            };

        case types.ERROR_PROMO_EBOOK:
            return {
                ...state,
                id: '',
                ebook: [],
                showing: 0,
                authorized: false,
                loading: false,
                error: true
            };

        default: return state;
    }
};

export default promoEbook;