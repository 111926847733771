import React, { useState, useEffect, useCallback, Fragment } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink, useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, Hidden, List, ListItem, ListItemText, Tooltip } from "@material-ui/core";
// import Archive from "@material-ui/icons/Archive";

// core components
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import NestedLink from "./NestedLink";
import clssNestedLink from "./Sidebar.module.css";
//import imgLogo from "../../assets/img/marxeed_logo_gradient.png";

import CreditDrawer from "components/CreditDrawer/CreditDrawer";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import BuildIcon from "@material-ui/icons/Build";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import AssistantIcon from "@material-ui/icons/Assistant";
import { formatDate } from "utils/helper";
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { drawerWidth } from "assets/jss/material-dashboard-react";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { handleDrawerOpen, handleDrawerClose } from "redux/actions/drawer";
import externalLinkIcon from "assets/img/adCopylink.png";

const useStyles = makeStyles(styles);

// verifies if routeName is the one active (in browser input)
export function activeRoute(routeName) {
  return window.location.href.indexOf(routeName) > -1 ? true : false;
}

export function getSearch(search) {
  const params = new URLSearchParams(window.location.search);
  params.delete("url");
  return "?" + params.toString();
}

function Sidebar({ handleDrawerToggle, isAuthorizedUser, credits, creditsAvailable, creditsUsage, loginSuccess, ...props }) {
  const { color, image, routes } = props;
  const stylesObj = useStyles();
  const [currentRoute, setRoute] = useState(window.location.pathname);
  const theme = useTheme();
  const dispatch = useDispatch();
  const {open} = useSelector((state) => state.changeDrawer)
  const history = useHistory();

  const onSetPath = useCallback(
    (path, isNested = false) => {
      setRoute((currPath) => (!isNested || currPath === path ? "" : path));
    },
    [setRoute]
  );

  const closeDrawer = useCallback(() => handleDrawerToggle(false), [handleDrawerToggle]);

  const [Routes, setRoutes] = useState(routes);
  useEffect(() => {
    if (!isAuthorizedUser) {
      //setRoutes(routes.filter((item) => !item.path.includes("/leaddata") && !item.path.includes("/toMarketing")));
      setRoutes(routes.filter((item) => !item.path.includes("/blogCuration")));
    } else {
      setRoutes(routes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorizedUser]);

  const [drawer, setDrawer] = useState(false);
  const toggleDrawer = () => {
    if (loginSuccess) {
      setDrawer(!drawer);
    }
  };
  const [usageData, setUsageData] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const show = Boolean(anchorEl);

  useEffect(() => {
    setUsageData(creditsUsage.map((item) => [item.credit_for, item.credits, `${formatDate(item.date_time)}`]));
  }, [creditsUsage]);

  const promotionMenu = () => {
    const r1 = routes[3]?.routes;
    return (<div>
      <div style={{ display: 'flex', fontSize: '14px', padding: '4px 0px', color: 'rgba(0, 0, 0, 0.7)', backgroundColor: 'rgb(160, 160, 160)', justifyContent: 'center', alignItems: 'center', border: ' 0px 0px 1px 0px solid rgba(0, 0, 0, 0.3)' }}>Promotions</div>
      <div style={{ padding: '5px' }}>
      {r1 &&
        r1.length !== 0 &&
        r1.map((prop, key) => {
          const listItemClasses = classNames({
            [" " + stylesObj[color]]: activeRoute(prop.path),
          });

          const whiteFontClasses = classNames({
            [" " + stylesObj.whiteFont]: activeRoute(prop.path),
          });
          return (
            <NavLink
              to={{
                pathname: prop.path,
                search: (prop.path.routes || []).includes(
                      "/subjectLines" || "/emailSample" || "/promotion" || "/hashtags" || "/promoQuora" || "/promoStackOverflow" || "/promoReddit"
                    )
                      ? props.location.search
                      : getSearch(props.location.search),
              }}
              activeClassName={`active ${clssNestedLink.active}`}
              key={key}
              exact
            >
              <ListItem selected={prop.path === window.location.pathname} id={`${prop.path.substring(1) }`} style={{ height: 30 }} button className={stylesObj.itemLink + whiteFontClasses}>
                <ListItemText
                  primary={prop.name === 'Ad Copy' ? 
                  <div style={{ height: 30, display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginRight: '8px' }}>{prop.name}</span>
                      <img style={{ width: '15px', height: '15px' }} src={externalLinkIcon} />
                  </div>
                  : prop.name}
                  style={{ color: "black",fontWeight:"350" }}
                  className={classNames(stylesObj.itemLinkText, whiteFontClasses)}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
          );
        })
      }
      </div>
    </div>)
  }

  const ResponsiveLinks = () => {
    return (
      <div className={clssNestedLink.rlList}>
        <div className={stylesObj.divider}></div>
        <List>
          <ListItem className={stylesObj.itemLink} onClick={() => window.open("https://calendly.com/aaic", "_blank")}>
            <AssistantIcon className={stylesObj.itemIcon} />
            <ListItemText primary="Request a demo" className={classNames(stylesObj.itemText, clssNestedLink.boldText)} disableTypography={true} />
          </ListItem>
          <ListItem className={stylesObj.itemLink}>
            <LocalOfferIcon className={stylesObj.itemIcon} />
            <ListItemText
              primary={`Credits ${loginSuccess ? `(${creditsAvailable})` : ""}`}
              className={classNames(stylesObj.itemText, clssNestedLink.boldText)}
              disableTypography={true}
              onClick={toggleDrawer}
            />
            <CreditDrawer open={drawer} toggleDrawer={toggleDrawer} credit={creditsAvailable} creditsUsage={usageData} />
          </ListItem>
          <ListItem className={stylesObj.itemLink} onClick={() => window.open("https://appliedaiconsulting.com/marxeed/services.html", "_blank")}>
            <BuildIcon className={stylesObj.itemIcon} />
            <ListItemText primary="Services" className={classNames(stylesObj.itemText, clssNestedLink.boldText)} disableTypography={true} />
          </ListItem>
          <ListItem className={stylesObj.itemLink} onClick={() => window.open("https://appliedaiconsulting.com/marxeed/support.html", "_blank")}>
            <LiveHelpIcon className={stylesObj.itemIcon} />
            <ListItemText primary="Support" className={classNames(stylesObj.itemText, clssNestedLink.boldText)} disableTypography={true} />
          </ListItem>
        </List>
      </div>
    );
  };

  const getListItem = (prop) => {
    const listItemClasses = classNames({
          [" " + stylesObj[color]]: activeRoute(prop.path),
    });

    const whiteFontClasses = classNames({
          [" " + stylesObj.whiteFont]: activeRoute(prop.path),
    });

    const routeActive = currentRoute === prop.path;
    var activeIconRoute = '';
    if(prop.path === '/promotion' && prop?.routes?.length) {
      prop.routes.forEach(route => {
        if(route?.path === window.location.pathname) {
          activeIconRoute = 'activeIcon'
        }
      });
    }
    else if(prop.path === window.location.pathname) {
      activeIconRoute = 'activeIcon'
    }

    return (
        <ListItem id={`${prop.path.substring(1)}`}
          button
          style={{ background: "white", paddingRight: '0px' }}
          onClick={() => {
            onSetPath.bind(this, prop.path, prop.isNested); 
            if(prop.path === '/promotion') {
              history.replace(`/qasites?id=${localStorage.getItem('marxeed_transaction_id')}`)
            }
          }}
          className={stylesObj.itemLink + listItemClasses + ` ${prop.isNested && routeActive ? clssNestedLink.nestedNavActive : ""}`}>
          {prop.icon ? (
            <Tooltip 
              title={ (!open && prop.name !== 'Promotions') ? prop.name : '' } 
              placement="right" 
              arrow
            >
              <span>
                <prop.icon
                  className={classNames(
                    stylesObj.itemIcon,
                    whiteFontClasses,
                    activeIconRoute,
                  )}
                />
              </span>
            </Tooltip>
          ) : (
            <prop className={classNames(stylesObj.itemIcon, whiteFontClasses)} />
          )}
          {open && <ListItemText primary={prop.name} className={classNames(stylesObj.itemText, clssNestedLink.boldText)} disableTypography={true} />}
          {/* {prop.isNested && <b className={clssNestedLink.caret}></b>} */}
        </ListItem>
    );
  }

  const links = (
    <List className={stylesObj.list} style={ open ? { paddingRight: '20px' } : { paddingRight: '0px', maxWidth: '100px' }}>
      {Routes.map((prop, index) => {

        return (
          prop.name && (
            <Fragment key={index}>
              {prop.isNested ? (
                <Typography
                  aria-owns={show ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <NestedLink
                    className={stylesObj.item}
                    active={currentRoute === prop.path}
                    promotionMenu = {promotionMenu}
                    routes={prop.routes}
                    color={color}
                    open={open}
                    show={show}
                    anchorEl={anchorEl}
                    handlePopoverClose={handlePopoverClose}
                    activeClassName={`active ${clssNestedLink.active}`}
                    location={props.location}
                    handleDrawerToggle={closeDrawer}>
                    {getListItem(prop)}
                  </NestedLink>
                </Typography>
              ) : (
                <NavLink
                  to={{
                    pathname: prop.path,
                    search: prop.path.includes("/trendingBlogs" || "/keywords" || "/toTarget" || "/contentCluster")
                      ? props.location.search
                      : getSearch(props.location.search),
                  }}
                  className={stylesObj.item}
                  activeClassName={`active ${clssNestedLink.active}`}
                  onClick={closeDrawer}>
                  {getListItem(prop)}
                </NavLink>
              )}
              {index === 4 && open ? <div className={stylesObj.divider}></div> : null}
              {index === 5 && props.mobileOpen && <ResponsiveLinks />}
            </Fragment>
          )
        );
      })}
    </List>
  );

  // var brand = (
  //   <div className={stylesObj.logo}>
  //     <a href="https://appliedaiconsulting.com/marxeed.html" className={classNames(stylesObj.logoLink)} target="_blank" rel="noopener noreferrer">
  //       <img className={stylesObj.logoImage} src={imgLogo} alt="marXeed" />
  //     </a>
  //   </div>
  // );

  // To ignore href check in anchor tag
  const getReportBtn = (
    <div>
      {/* eslint-disable-next-line */}
      <a className={`${stylesObj.item} ${clssNestedLink.active} ${clssNestedLink.freeReportBtn}`} onClick={() => props.openPayment(true)}>
        {/* <ListItem button
        className={`${stylesObj.itemLink}  ${stylesObj[color]}`}>
        <Archive
          className={classNames(stylesObj.itemIcon, stylesObj.whiteFont, clssNestedLink.iconRightPadding)}
        />
        <ListItemText
          primary='Get Free Report'
          className={classNames(stylesObj.itemText, stylesObj.whiteFont, clssNestedLink.boldText)}
          disableTypography={true}
        />
      </ListItem> */}
      </a>
      {/* eslint-disable-next-line */}
      <a className={`${stylesObj.item} ${clssNestedLink.active} ${clssNestedLink.fullReportBtn}`} onClick={() => props.openPayment(false)}>
        {/* <ListItem button
        className={`${stylesObj.itemLink}  ${stylesObj[color]}`}>
        <Archive
          className={classNames(stylesObj.itemIcon, stylesObj.whiteFont, clssNestedLink.iconRightPadding)}
        /> 
         <ListItemText
          // primary='Get Complete Report (Paid)'
          className={classNames(stylesObj.itemText, stylesObj.whiteFont, clssNestedLink.boldText)}
          disableTypography={true}
        />
      </ListItem> */}
      </a>
    </div>
  );

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: open ? 'flex-start' : 'center',
    paddingLeft: open ? '20px':'0px',
    fontSize: '13',
    fontWeight: '300',
    color: 'rgba(160, 160, 160, 1)',
  }));

  const SideDrawer = props.mobileOpen ? Drawer : (styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: open ? drawerWidth : '100px',
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      justifyContent: 'flex-end',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  ));


  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          id="Drawer"
          variant="temporary"
          anchor={"right"}
          open={props.mobileOpen}
          classes={{
            paper: classNames(stylesObj.drawerPaper),
          }}
          onClose={() => handleDrawerToggle()}
          ModalProps={{
            keepMounted: false, // Better open performance on mobile.
          }}>
          {/* {brand} */}
          <div className={stylesObj.sidebarWrapper1}>
            {/* {mediaWidth < 576 && brand} */}
            {links}
            {getReportBtn}
          </div>
          {image !== undefined ? <div className={stylesObj.background} style={{ backgroundImage: "url(" + image + ")" }} /> : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <SideDrawer
          anchor="left"
          variant="permanent"
          open={open}
          classes={{
            paper: classNames(stylesObj.drawerPaper),
          }}
          sx= {{
            "& .MuiPaper-root": {
              top: '75px',
            }
          }}
        >
          <div className={stylesObj.sidebarWrapper}>
            {links}
            {getReportBtn}
          </div>
          {image !== undefined ? <div className={stylesObj.background} style={{ backgroundImage: "url(" + image + ")" }} /> : null}
          <div className={stylesObj.collapseBtn}>
            {open ? (
              <DrawerHeader onClick={() => dispatch(handleDrawerClose())} style={{display: 'flex', flexDirection: 'row'}}>
                <div>
                  <KeyboardDoubleArrowLeftIcon />
                </div>
                <div style={{paddingLeft: '18px'}}>Collapse</div>
              </DrawerHeader>
              ) : (
                <Tooltip title="Toggle Sidebar" enterDelay={500}>
                  <DrawerHeader onClick={() => dispatch(handleDrawerOpen())}>
                    <KeyboardDoubleArrowRightIcon />
                  </DrawerHeader>
                </Tooltip>
              )
            }
          </div>
        </SideDrawer>
      </Hidden>
    </div>
  );
}

//export default React.memo(Sidebar);

const mapStateToProps = ({ auth, credits }) => ({
  ...auth,
  creditsAvailable: credits.availableCredits,
  creditsUsage: credits.creditsUsage,
});

export default connect(
  mapStateToProps,
  null
)(Sidebar);

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["primary", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
};

Sidebar.defaultProps = {
  handleDrawerToggle: () => { },
};
