import React from 'react';

const LogoutIco = ({
    ...props
}) => <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
width="1504.000000pt" height="886.000000pt" viewBox="0 0 1504.000000 886.000000"
preserveAspectRatio="xMidYMid meet" {...props}>

<g transform="translate(0.000000,886.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M5954 7376 c-54 -25 -94 -69 -113 -125 -8 -24 -11 -308 -11 -1012 l0
-979 441 0 c425 0 443 -1 474 -20 68 -42 65 2 65 -927 0 -935 4 -882 -69 -930
l-34 -23 -438 0 -439 0 0 -982 0 -983 24 -51 c26 -57 58 -85 119 -109 38 -14
218 -15 1828 -13 l1786 3 49 30 c28 18 58 47 71 70 l23 40 0 2946 0 2945 -29
46 c-20 32 -44 53 -82 72 l-53 26 -1780 0 -1781 0 -51 -24z m1366 -3066 l0
-950 -140 0 -140 0 0 950 0 950 140 0 140 0 0 -950z"/>
<path d="M4942 4908 l-592 -593 592 -593 593 -592 5 207 c6 230 11 251 74 289
29 18 61 19 469 22 l437 3 0 669 0 670 -444 0 -444 0 -31 26 c-54 45 -56 54
-61 277 l-5 207 -593 -592z"/>
</g>
</svg>;

export default LogoutIco;