import { GET_STATUS, GET_STATUS_SUCCESS, GET_STATUS_FAILURE } from '../constants/status';

const initialState = {
    id: '',
    status : [],
    completed: false,
    isPolling: false   
};

const status = (state = initialState, action) => {
    switch (action.type) {
        case GET_STATUS:
            return {
                ...state,
                completed: false,
                isPolling: true,
                id: action.id
            };

        case GET_STATUS_SUCCESS:
            return {
                ...state,
                id: action.id,
                status : action.status && action.status[0].toLowerCase() === 'success' ?  action.status[1] : 'went wrong' , 
                completed: true,
                isPolling: false,
            };

        case GET_STATUS_FAILURE:
            return {
                ...state,
                isPolling: true,
                completed: false
            };

        default:
            return state;
    }
};

export default status;
