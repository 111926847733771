import * as types from '../constants/promoGeneratedBlog';

const initialState = {
    loading: false,
    error: false,
    generatedBlogs: []
};

const promoGeneratedBlog = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_GENERATED_BLOGS:
            return {
                ...state,
                loading: true,
            };

        case types.SET_GENERATED_BLOGS:
            const response = action.response;
            return {
                ...state,
                loading: false,
                error: false,
                generatedBlogs: response || [],
            };

        case types.ERROR_GENERATED_BLOGS:
            return {
                ...state,
                loading: false,
                error: true,
            };

        default: return state;
    }
};

export default promoGeneratedBlog;