
import { GET_TARGET_ACC, SET_TARGET_ACC, ERROR_TARGET_ACC } from '../constants/targetAccount';

export const getTargetAccounts = (id) => ({
    type: GET_TARGET_ACC,
    id
});

export const setTargetAccounts = (id, targetAccounts, found, showing, authorized) => ({
    type: SET_TARGET_ACC,
    id,
    targetAccounts,
    found,
    showing,
    authorized
});

export const getTargetAccountsFailed = () => ({
    type: ERROR_TARGET_ACC
});
