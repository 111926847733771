import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from './layouts/Main';
import { Provider } from 'react-redux';
import store from './redux/store';


export default function App() {
    return <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route component={Main} />
            </Switch>
        </BrowserRouter>
    </Provider>
}