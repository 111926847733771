
import { GET_SUBLINE, SET_SUBLINE, ERROR_SUBLINE } from '../constants/recommSubLine';

export const getSubLines = (id) => ({
    type: GET_SUBLINE,
    id
});

export const setSubLines = (id, subLines, found, showing, authorized) => ({
    type: SET_SUBLINE,
    id,
    subLines,
    found,
    showing,
    authorized
});

export const getSubLinesFailed = () => ({
    type: ERROR_SUBLINE
});
