import { GET_CREDITS, SET_CREDITS_AVAILABLE, SET_CREDITS_USAGE, ERROR_CREDITS } from "../constants/credits";

export const getCredits = (userEmail) => ({
  type: GET_CREDITS,
  userEmail,
});

export const setCreditsAvailable = (availableCredits) => ({
  type: SET_CREDITS_AVAILABLE,
  availableCredits,
});

export const setCreditsUsage = (creditsUsage) => ({
  type: SET_CREDITS_USAGE,
  creditsUsage,
});

export const getCreditsFailed = () => ({
  type: ERROR_CREDITS,
});
