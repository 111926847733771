import * as types from "../constants/drawer";

const initialState = {
  open: true,
}

const changeDrawer = (state = initialState, { type, ...action }) => {
  switch (type) {
    case types.DRAWER_OPEN:
      return {
        ...state,
        open: true,
      }
    
    case types.DRAWER_CLOSE:
      return {
        ...state,
        open: false,
      }
  
    default:
      return state;
  }
}

export default changeDrawer;