import { GET_EXEC_HISTORY, SET_EXEC_HISTORY, ERROR_EXEC_HISTORY } from "../constants/execHistory";

export const getExecHistory = () => ({
  type: GET_EXEC_HISTORY,
});

export const setExecHistory = (execHistory) => ({
  type: SET_EXEC_HISTORY,
  execHistory,
});

export const getExecHistoryFailed = () => ({
  type: ERROR_EXEC_HISTORY,
});
