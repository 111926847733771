
import { GET_TRENDING_BLOGS, SET_TRENDING_BLOGS, ERROR_TRENDING_BLOGS, GENERATE_AI_BLOG, SET_GENERATE_AI_BLOG, ERROR_GENERATE_AI_BLOG, GET_AI_BLOG } from '../constants/trendingBlogs';

export const getTrendingBlogs = (id) => ({
    type: GET_TRENDING_BLOGS,
    id
});

// For Generate AI Blog
export const generateAiBlog = (prompt) => ({
    type: GENERATE_AI_BLOG,
    prompt
});

export const setGenerateAiBlog = (prompt, data, authorized) => ({
    type: SET_GENERATE_AI_BLOG,
    prompt,
    data,
    authorized
});
export const getAiBlog = () => ({
    type: GET_AI_BLOG
});

export const generateAiBlogFailed = () => ({
    type: ERROR_GENERATE_AI_BLOG
});

export const setTrendingBlogs = (id, trendingBlogs, found, showing, authorized) => ({
    type: SET_TRENDING_BLOGS,
    id,
    trendingBlogs,
    found,
    showing,
    authorized
});

export const getTrendingBlogsFailed = () => ({
    type: ERROR_TRENDING_BLOGS
});
