import { put, takeLeading, call, select } from "redux-saga/effects";
import { GET, POST } from "../../utils/api.utility";

// import for Search Details
import { GET_SEARCH, POST_SEARCH } from "../constants/search";
import { setSearchDetails, getSearchDetailsFailed } from "../actions/search";

// import for Keywords
import { GET_KEYWORDS } from "../constants/keyword";
import { setKeywords, getKeywordsFailed } from "../actions/keyword";

// import for Ccl Keywords Mindmap
import { GET_CCL_KEYWORDS } from "../constants/mindMap";
import { setCclKeywordsMindmap, getCclKeywordsMindmapFailed } from "../actions/mindMap";

// import for Ccl Titles Mindmap
import { GET_CCL_TITLES } from "../constants/mindMapTitle";
import { setCclTitlesMindmap, getCclTitlesMindmapFailed } from "../actions/mindMapTitle";

// imports for Target Accounts
import { GET_TARGET_ACC } from "../constants/targetAccount";
import { setTargetAccounts, getTargetAccountsFailed } from "../actions/targetAccount";

// imports for Recommended Subject Lines
import { GET_SUBLINE } from "./../constants/recommSubLine";
import { setSubLines, getSubLinesFailed } from "../actions/recommSubLine";

// imports for Generated Email Sample
import { GET_EMAIL_SAMPLE } from "./../constants/genEmailSample";
import { setEmailSample, getEmailSampleFailed } from "../actions/genEmailSample";

// imports for History
import { GET_EXEC_HISTORY } from "../constants/execHistory";
import { setExecHistory, getExecHistoryFailed } from "../actions/execHistory";

// imports for ABM History
import { GET_HISTORY } from "../constants/abmHistory";
import { setHistory, getHistoryFailed } from "../actions/abmHistory";

//imports for ABM Report
import { GET_REPORT_ID } from "../constants/abmReport";
import { setReport, getReportFailed } from "../actions/abmReport";

// imports for ABM Invoke
import { POST_INVOKE } from "../constants/abmInvoke";
import { setInvoke } from "../actions/abmInvoke";

//imports for CREDITS
import { GET_CREDITS } from "../constants/credits";
import { getCreditsFailed, setCreditsUsage, setCreditsAvailable } from "../actions/credits";

import { POST_PAYMENT_COMPLETED } from "./../constants/status";
import { openAlert } from "./../actions/alert";
import { getTokenHeader } from "redux/utility";

function* getSearchById({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const { keywords, email, location, company, name } = yield call(GET, `/invoke?id=${id}`, header);

    if (id && keywords) {
      yield put(setSearchDetails(id, email, keywords, location, company, name));
    } else {
      yield put(getSearchDetailsFailed());
    }
  } catch (error) {
    yield put(getSearchDetailsFailed());
  }
}

function* getKeywordsById({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const { keywords, found, showing, authorized } = yield call(GET, `/keywords?id=${id}`, header);

    if (id && keywords) {
      yield put(setKeywords(id, keywords, found, showing, authorized));
    } else {
      yield put(getKeywordsFailed());
    }
  } catch (error) {
    yield put(getKeywordsFailed());
  }
}

function* getExecutionHistory() {
  try {
    const header = yield select(getTokenHeader);
    const execHistory = yield call(GET, `/history`, header);

    if (execHistory) {
      yield put(setExecHistory(execHistory));
    } else {
      yield put(getExecHistoryFailed());
    }
  } catch (error) {
    yield put(getExecHistoryFailed());
  }
}

function* getAbmHistoryById(user_id) {
  try {
    const header = yield select(getTokenHeader);
    const abmHistory = yield call(GET, `/abm/history?user_id=${user_id.user_id}`, header);
    if (user_id && abmHistory) {
      yield put(setHistory(abmHistory));
    } else {
      yield put(getHistoryFailed());
    }
  } catch (error) {
    yield put(getHistoryFailed());
  }
}

function* postNewReport({ user_id, company_name, emails }) {
  try {
    const header = yield select(getTokenHeader);
    const data = yield call(POST, `/abm/invoke`, header, { user_id, company_name, emails });
    //console.log("Data Request for ABN Invoke :", data);
    if (data) {
      yield put(setInvoke(data));
    }
    yield put(openAlert("Thank you!", "We have processed your request. Please refresh and see the Table for your respective Search."));
  } catch (error) {
    //console.log("Error in postNewReport Saga", error);
  }
}
function* fetchReportId({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const getReportURL = yield call(GET, `/abm/send_report?id=${id}`, header);
    var obj = { id: id, URL: getReportURL };
    if (getReportURL) {
      yield put(setReport(obj));
    } else {
      yield put(getReportFailed());
    }
  } catch (error) {
    yield put(getReportFailed());
  }
}

function* getCclKeywordsMindmapById({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const { ccl_keywords_mindmap, found, showing, authorized } = yield call(GET, `/ccl_keywords_mindmap?id=${id}`, header);

    if (id && ccl_keywords_mindmap) {
      yield put(setCclKeywordsMindmap(id, ccl_keywords_mindmap, found, showing, authorized));
    } else {
      yield put(getCclKeywordsMindmapFailed());
    }
  } catch (error) {
    yield put(getCclKeywordsMindmapFailed());
  }
}

function* getCclTitlesMindmapById({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const { ccl_titles_mindmap, found, showing, authorized } = yield call(GET, `/ccl_titles_mindmap?id=${id}`, header);

    if (id && ccl_titles_mindmap) {
      yield put(setCclTitlesMindmap(id, ccl_titles_mindmap, found, showing, authorized));
    } else {
      yield put(getCclTitlesMindmapFailed());
    }
  } catch (error) {
    yield put(getCclTitlesMindmapFailed());
  }
}

function* getTargetAccountsById({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const { targetaccounts, found, showing, authorized } = yield call(GET, `/targetaccounts?id=${id}`, header);

    if (id && targetaccounts) {
      yield put(setTargetAccounts(id, targetaccounts, found, showing, authorized));
    } else {
      yield put(getTargetAccountsFailed());
    }
  } catch (error) {
    yield put(getTargetAccountsFailed());
  }
}

function* getRecommSubLineById({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const { subjectlines, found, showing, authorized } = yield call(GET, `/subjectlines?id=${id}`, header);

    if (id && subjectlines) {
      yield put(setSubLines(id, subjectlines, found, showing, authorized));
    } else {
      yield put(getSubLinesFailed());
    }
  } catch (error) {
    yield put(getSubLinesFailed());
  }
}

function* getEmailSampleById({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const { emailsample } = yield call(GET, `/emailsample?id=${id}`, header);

    if (id && emailsample) {
      yield put(setEmailSample(id, emailsample));
    } else {
      yield put(getEmailSampleFailed());
    }
  } catch (error) {
    yield put(getEmailSampleFailed());
  }
}

function* getCreditsByOrgId({ userEmail }) {
  try {
    const header = yield select(getTokenHeader);
    const { org_id } = yield call(GET, `/org_id?user_email=${userEmail}`, header);
    const { available_credits } = yield call(GET, `/credits?org_id=${org_id}`, header);
    if (available_credits >= 0) {
      yield put(setCreditsAvailable(available_credits));
    } else {
      yield put(getCreditsFailed());
    }
    const creditsUsage = yield call(GET, `/credits/usage?org_id=${org_id}`, header);
    if (creditsUsage) {
      yield put(setCreditsUsage(creditsUsage));
    } else {
      yield put(getCreditsFailed());
    }
  } catch (error) {
    yield put(getCreditsFailed());
  }
}

function* postNewKeyword({ email, keywords, location, company }) {
  try {
    const header = yield select(getTokenHeader);
    yield call(POST, `/invoke`, header, { email, keywords, location, company });

    yield put(openAlert("Thank you!", "We have received your request. We will share your sample report in the next 24 hours."));
  } catch (error) {
    //console.log("Error in postNewKeyword Saga", error);
  }
}

// Post Payment API Call
const getId = (state) => state.status.id;

function* postPaymentCompleted({ email, keywords, location, company, order_id }) {
  try {
    const header = yield select(getTokenHeader);
    const id = yield select(getId);

    const response = yield call(POST, `/payment?id=${id}`, header, {
      id,
      email,
      keywords,
      location,
      company,
      order_id,
    });

    if (response === "COMPLETED") {
      yield put(
        openAlert(
          "Thank you!",
          "Thank you for choosing MarXeed. We have received your payment and we will share your sample report in the next 24 hours."
        )
      );
    } else {
      yield put(
        openAlert(
          "Something went wrong!",
          "We regret to inform you that your payment verification has failed. If any amount is debited, it will be refunded by your bank within 5 working days."
        )
      );
    }
  } catch (error) {
    yield put(
      openAlert(
        "Something went wrong!",
        "We regret to inform you that your payment verification has failed. If any amount is debited, it will be refunded by your bank within 5 working days."
      )
    );
    //console.log("Error while calling Post Payment API", error);
  }
}

export default function* getData() {
  yield takeLeading(GET_SEARCH, getSearchById);
  yield takeLeading(GET_KEYWORDS, getKeywordsById);
  yield takeLeading(GET_TARGET_ACC, getTargetAccountsById);
  yield takeLeading(GET_SUBLINE, getRecommSubLineById);
  yield takeLeading(GET_EMAIL_SAMPLE, getEmailSampleById);
  yield takeLeading(GET_EXEC_HISTORY, getExecutionHistory);
  yield takeLeading(POST_SEARCH, postNewKeyword);
  yield takeLeading(POST_PAYMENT_COMPLETED, postPaymentCompleted);
  yield takeLeading(GET_CCL_KEYWORDS, getCclKeywordsMindmapById);
  yield takeLeading(GET_CCL_TITLES, getCclTitlesMindmapById);
  yield takeLeading(GET_HISTORY, getAbmHistoryById);
  yield takeLeading(POST_INVOKE, postNewReport);
  yield takeLeading(GET_REPORT_ID, fetchReportId);
  yield takeLeading(GET_CREDITS, getCreditsByOrgId);
}
