
import { GET_BLOG_HEADERS, SET_BLOG_HEADERS, ERROR_BLOG_HEADERS } from './../constants/blogHeaders';

export const getBlogHeaders = (url) => ({
    type: GET_BLOG_HEADERS,
    url
});

export const setBlogHeaders = (url, blogHeaders, authorized) => ({
    type: SET_BLOG_HEADERS,
    url,
    blogHeaders,
    authorized
});

export const getBlogHeadersFailed = () => ({
    type: ERROR_BLOG_HEADERS
});
