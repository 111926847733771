import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont,
} from "assets/jss/material-dashboard-react.js";

const tableStyle = (theme) => ({
  warningTableHeader: {
    color: warningColor[0],
  },
  primaryTableHeader: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  dangerTableHeader: {
    color: dangerColor[0],
  },
  successTableHeader: {
    color: successColor[0],
  },
  infoTableHeader: {
    color: infoColor[0],
  },
  roseTableHeader: {
    color: roseColor[0],
  },
  grayTableHeader: {
    color: grayColor[0],
  },
  table: {
    marginBottom: "0",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: "transparent",
    borderSpacing: "0",
    borderCollapse: "collapse",
  },
  tableHeadCell: {
    color: "#fff",
    fontSize: 8,
    minWidth: 70,
    "&, &$tableCell": {
      fontSize: 14,
      padding: "8px 8px",
      fontWeight: "700",
    },
  },
  tableCell: {
    ...defaultFont,
    lineHeight: "1.42857143",
    padding: "12px 8px",
    verticalAlign: "middle",
    fontSize: "0.845rem",
  },
  tableResponsive: {
    width: "100%",
    overflowX: "auto",
  },
  tableHeadRow: {
    height: "46px",
    color: "#ffff",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
    background: "#89B0CF",
  },
  tableBodyRow: {
    height: "48px",
    color: "inherit",
    display: "table-row",
    outline: "none",
    verticalAlign: "middle",
  },
  tableCustomResponsive: {
    marginTop: "0px",
  },
  tableCustomInput: {
    marginTop: "0px",
    marginBottom: "0px",
  },
  warningBorder: {
    borderColor: warningColor[0],
  },
  primaryBorder: {
    borderColor: primaryColor[0],
  },
  dangerBorder: {
    borderColor: dangerColor[0],
  },
  successBorder: {
    borderColor: successColor[0],
  },
  infoBorder: {
    borderColor: infoColor[0],
  },
  roseBorder: {
    borderColor: roseColor[0],
  },
  grayBorder: {
    borderColor: grayColor[0],
  },
});

export default tableStyle;
