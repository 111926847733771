import { DRAWER_OPEN, DRAWER_CLOSE } from "redux/constants/drawer"

export const handleDrawerOpen = () => {
  return {
    type: DRAWER_OPEN,
  }
}

export const handleDrawerClose = () => {
  return {
    type: DRAWER_CLOSE,
  }
}