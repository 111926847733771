import { put, takeLeading, call, select } from 'redux-saga/effects';
import { GET } from '../../utils/api.utility';

// import for Cluster Keyword and Tiles 
import { GET_CLUSTER_KEYWORDS, GET_CLUSTER_TILES } from '../constants/contentCluster';
import {
    setClusterKeywords, getClusterKeywordsFailed,
    setClusterTiles, getClusterTilesFailed
} from '../actions/contentCluster';
import { getTokenHeader } from 'redux/utility';


function* getClusterKeywords({ id }) {
    try {
        const header = yield select(getTokenHeader);
        const { contentclusterkeywords, found, showing, authorized } = yield call(GET,
            `/contentclusterkeywords?id=${id}`,
            header
        );

        if (id && contentclusterkeywords) {
            yield put(setClusterKeywords(id, contentclusterkeywords, found, showing, authorized));
        } else {
            yield put(getClusterKeywordsFailed());
        }
    } catch (error) {
        yield put(getClusterKeywordsFailed());
    }
}

function* getClusterTiles({ id }) {
    try {
        const header = yield select(getTokenHeader);
        const { contentclustertitles, found, showing, authorized } = yield call(GET,
            `/contentclustertitles?id=${id}`,
            header
        );

        if (id && contentclustertitles) {
            yield put(setClusterTiles(id, contentclustertitles, found, showing, authorized));
        } else {
            yield put(getClusterTilesFailed());
        }
    } catch (error) {
        yield put(getClusterTilesFailed());
    }
}


export default function* getInboundData() {
    yield takeLeading(GET_CLUSTER_KEYWORDS, getClusterKeywords);
    yield takeLeading(GET_CLUSTER_TILES, getClusterTiles);
}