import { combineReducers } from "redux";
import auth from "./auth";
import keyword from "./keyword";
import targetAccount from "./targetAccount";
import recommSubLine from "./recommSubLine";
import contentCluster from "./contentCluster";
import genEmailSample from "./genEmailSample";
import promoQuora from "./promoQuora";
import promoHashTag from "./promoHashTag";
import promoGeneratedBlog from "./promoGeneratedBlog";
import promoStack from "./promoStack";
import promoReddit from "./promoReddit";
import promoYoutube from "./promoYoutube";
import promoLinkedin from "./promoLinkedin";
import promoEbook from "./promoEbook";
import getSearchDetails from "./search";
import status from "./status";
import alert from "./alert";
import execHistory from "./execHistory";
import abmHistory from "./abmHistory";
import trendingBlogs from "./trendingBlogs";
import blogHeaders from "./blogHeaders";
import cloudWords from "./wordCloud";
import mindMap from "./mindMap";
import mindMapTitle from "./mindMapTitle";
import abmInvoke from "./abmInvoke";
import abmReport from "./abmReport";
import generateAiBlog from "./generateAiBlog";
import snackbar from "./snackbar";
import credits from "./credits";
import changeDrawer from "./drawer";
import youtubeContent from "./youtubeContent";

const reducer = combineReducers({
  search: getSearchDetails,
  auth,
  keyword,
  targetAccount,
  recommSubLine,
  contentCluster,
  genEmailSample,
  promoYoutube,
  promoLinkedin,
  promoQuora,
  promoHashTag,
  promoGeneratedBlog,
  promoStack,
  promoReddit,
  promoEbook,
  status,
  execHistory,
  alert,
  trendingBlogs,
  generateAiBlog,
  blogHeaders,
  cloudWords,
  mindMap,
  mindMapTitle,
  abmInvoke,
  abmHistory,
  abmReport,
  snackbar,
  credits,
  changeDrawer,
  youtubeContent,
});

export default reducer;
