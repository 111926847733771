import { takeLeading, select, call, put } from "redux-saga/effects";
import { POST_LOGIN, LOGIN_SUCCESS, LOCAL_AUTH_CHECK, LOGOUT, CALL_CHECKPOINT } from "../constants/auth";
import { getTokenHeader } from "redux/utility";
import { GET } from "utils/api.utility";
import { onLoginSuccess } from "redux/actions/auth";
import { onLocalAuthCompleted, onPostLoggout } from "./../actions/auth";
import { Auth } from "aws-amplify";

const AUTH_RESPONSE = "marxeed_cred";

const onTokenValidation = async () => {
  try {
    const session = await Auth.currentSession();
    var idTokenExpire = session.getIdToken().getExpiration();
    var refreshToken = session.getRefreshToken();
    var currentTimeSeconds = Math.round(+new Date() / 1000);

    if (idTokenExpire < currentTimeSeconds) {
      const currentData = await Auth.currentAuthenticatedUser();
      currentData.refreshSession(refreshToken, async (err, data) => {
        if (err) {
          await Auth.signOut();
        } else {
          const profileData = {
            tokenId: data?.idToken.jwtToken,
            profileObj: {
              imageUrl: data?.idToken.payload.picture,
              givenName: data?.idToken.payload.given_name,
              familyName: data?.idToken.payload.family_name,
              email: data?.idToken.payload.email,
              refreshToken: data?.refreshToken.token,
              accessToken: data?.accessToken.jwtToken,
            },
            expires_at: data?.idToken.payload.exp,
          };
          return { isExpired: true, profileData };
        }
      });
    } else {
      return { isExpired: false };
    }
  } catch (e) {
    return { isExpired: false };
  }
};

function* getResponseAuthSaga() {
  try {
    const validationPayload = yield call(onTokenValidation);

    if (validationPayload.isExpired) {
      yield put(onLoginSuccess(validationPayload.profileData));
    }
    yield put(onLocalAuthCompleted());
  } catch (error) {
    console.log("Error while calling storeAuthSaga", error);
  }
}

// function* postLoginSaga() {
//   try {
//     const header = yield select(getTokenHeader);
//     yield call(GET, `/login`, header);
//   } catch (error) {
//     console.log("Error while calling postLoginSaga", error);
//   }
// }

function* callCheckPointSaga({ id }) {
  try {
    const checkPointValidation = yield call(onTokenValidation);
    if (!checkPointValidation.isExpired) {
      const header = yield select(getTokenHeader);
      yield call(GET, `/checkpoint?id=${id}`, header);
    }
  } catch (error) {
    console.log("Error while calling callCheckPointSaga", error);
  }
}

function storeAuthSaga({ response }) {
  try {
    localStorage.setItem(AUTH_RESPONSE, JSON.stringify(response));
    localStorage.setItem("token_header", response.tokenId);
  } catch (error) {
    console.log("Error while calling storeAuthSaga", error);
  }
}

function* postLogoutSaga() {
  try {
    localStorage.clear(AUTH_RESPONSE);
    const header = yield select(getTokenHeader);
    yield call(GET, `/logout`, header);
    yield put(onPostLoggout());
  } catch (error) {
    console.log("Error while calling postLoginSaga", error);
  }
}

export default function* authSaga() {
  //yield takeLeading(POST_LOGIN, postLoginSaga);
  yield takeLeading(LOGIN_SUCCESS, storeAuthSaga);
  yield takeLeading(LOCAL_AUTH_CHECK, getResponseAuthSaga);
  yield takeLeading(LOGOUT, postLogoutSaga);
  yield takeLeading(CALL_CHECKPOINT, callCheckPointSaga);
}
