import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";

// core components
import styles from "./tableStyle.js";
import { stableSort } from "../../utils/sort";
import classObj from "./CustomTable.module.css";
const useStyles = makeStyles(styles);

export default function CustomTable({
  tableHead,
  tableData,
  tableHeaderColor,
  defaultOrderBy,
  onRowClick,
  rowClass,
  hubspotButton,
  withSearch,
  search,
  keywords,
  runID,
  showBottomStrip = true,
  pointer = true,
  creditDrawerStyle = false,
  historyStyle = false,
  columnSize = 4
}) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  //const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState(tableData);
  const [active, setActive] = useState(runID);

  // const handleSearchInput = useCallback(
  //   (event) => {
  //     setSearch(event.target.value);
  //   },
  //   [setSearch]
  // );

  useEffect(() => {
    const timer = setTimeout(() => {
      setActive(localStorage.getItem("marxeed_transaction_id"));
    }, 100);

    return () => clearTimeout(timer);
  }, [keywords, runID]);

  const handleChangePage = useCallback(
    (event, newPage) => {
      setPage(newPage);
    },
    [setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    },
    [setRowsPerPage, setPage]
  );

  const handleRequestSort = useCallback(
    (property) => {
      const isDesc = orderBy === property && order === "desc";
      setOrder(isDesc ? "asc" : "desc");
      setOrderBy(property);
    },
    [orderBy, setOrder, setOrderBy, order]
  );

  useEffect(() => {
    let rawArray = tableData;

    // Filter if search valid
    if (search) {
      rawArray = [
        ...tableData.filter((array) => {
          let isPresent = false;
          array.forEach((element) => {
            if (
              search &&
              element &&
              ((typeof element === "string" && element.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
                (typeof element !== "string" && element.props.text.toLowerCase().indexOf(search.toLowerCase()) > -1))
            ) {
              isPresent = true;
              return;
            }
          });
          return isPresent;
        }),
      ];
    }

    // Sort filtered Array.
    rawArray = [...stableSort(rawArray, order, orderBy)];

    setFilterData(rawArray);
  }, [tableData, search, setFilterData, order, orderBy]);

  // const onItemClick = (prop) => {
  //   onRowClick(prop);
  // };
  return (
    <div className={`${classes.tableResponsive} ${classes.tableCustomResponsive} ${classObj.tableScroll}`}>
      <div style={{ maxHeight: "100%" }}>
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead style={{ position: "sticky", top: 0 }} className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow}>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                      <TableSortLabel
                        classes={{
                          active: classes.tableSortHeader,
                          icon: classes.tableSortIcon,
                        }}
                        direction={order}
                        onClick={handleRequestSort.bind(this, key)}
                        active={key === orderBy}>
                        {prop}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody
            classes={{
              root: classObj.stripped,
            }}>
            {(rowsPerPage < 0 ? filterData : filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((prop, key) => {
              return (
                <TableRow
                  style={{
                    cursor: pointer ? "pointer" : "auto",
                    color: active === prop[4] ? "rgb(26, 170, 193)" : "rgba(0, 0, 0, 0.87)",
                    border: active === prop[4] ? "1.5px solid rgb(44 144 160 / 74%)" : "",
                  }}
                  onClick={onRowClick?.bind(this, prop, key)}
                  key={key}
                  className={`${classes.tableBodyRow} ${rowClass}`}
                >
                  {(prop.slice(0, columnSize).map((prop, key) => {
                    if (!isNaN(prop)) {
                      return (
                        <TableCell style={{ color: Math.sign(prop) == 1 ? "green" : "red" }} className={classes.tableCell} key={key}>
                          {prop}
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell style={{ color: "inherit" }} className={classes.tableCell} key={key}>
                          {prop}
                        </TableCell>
                      );
                    }
                  }))
                  }
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {
        showBottomStrip && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
            component="div"
            count={filterData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "previous page",
            }}
            nextIconButtonProps={{
              "aria-label": "next page",
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            style={
              creditDrawerStyle
                ? { minHeight: 52, paddingRight: 2, position: "relative", left: 0, bottom: "5px", background: "white" }
                : { position: "absolute", bottom: 0, width: "100%", left: 0, background: "#fff" }
            }
            style={
              historyStyle
                ? { position: "absolute", bottom: "-52px", width: "100%", left: 0, background: "#fff" }
                : { position: "absolute", bottom: 0, width: "100%", left: 0, background: "#fff" }
            }
            classes={{
              toolbar: classObj.toolbar,
              selectRoot: classObj.selectRoot,
              actions: classObj.actions,
              caption: classObj.caption,
            }}
          />
        )
      }
    </div >
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "primary",
  defaultOrderBy: 0,
  onRowClick: () => { },
  rowClass: "",
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf(["warning", "primary", "danger", "success", "info", "rose", "gray"]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))).isRequired,
  defaultOrderBy: PropTypes.number,
  onRowClick: PropTypes.func,
  rowClass: PropTypes.string,
};
