import { GET_PROMO_LINKEDIN, SET_PROMO_LINKEDIN, ERROR_PROMO_LINKEDIN } from "../constants/promoLinkedin";

export const getPromoLinkedin = (id) => ({
  type: GET_PROMO_LINKEDIN,
  id,
});

export const setPromoLinkedin = (linkedin) => ({
  type: SET_PROMO_LINKEDIN,
  linkedin,
});

export const getPromoLinkedinFailed = () => ({
  type: ERROR_PROMO_LINKEDIN,
});
