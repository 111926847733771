import { GET_PROMO_EBOOK, SET_PROMO_EBOOK, ERROR_PROMO_EBOOK } from "../constants/promoEbook";

export const getPromoEbook = (id) => ({
  type: GET_PROMO_EBOOK,
  id,
});

export const setPromoEbook = (id, ebook, showing, authorized) => ({
  type: SET_PROMO_EBOOK,
  id,
  ebook,
  showing,
  authorized,
});

export const getPromoEbookFailed = () => ({
  type: ERROR_PROMO_EBOOK,
});
