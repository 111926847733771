import * as types from '../constants/recommSubLine';

const initialState = {
    id: '',
    subLines: [],
    found: 0,
    showing: 0,
    authorized: false,
    loading: false,
    error: false
};

const recommSubLine = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_SUBLINE:
            return {
                ...state,
                loading: true,
                error: false
            };

        case types.SET_SUBLINE:
            return {
                ...state,
                id: action.id,
                subLines: action.subLines,
                found: action.found,
                showing: action.showing,
                authorized: action.authorized,
                loading: false,
                error: false
            };

        case types.ERROR_SUBLINE:
            return {
                ...state,
                id: '',
                subLines: [],
                found: 0,
                showing: 0,
                authorized: false,
                loading: false,
                error: true
            };

        default: return state;
    }
};

export default recommSubLine;