import * as types from "../constants/credits";

const initialState = {
  loading: true,
  availableCredits: 0,
  creditsUsage: [],
};

const credits = (state = initialState, { type, ...action }) => {
  switch (type) {
    case types.GET_CREDITS:
      return {
        ...state,
        error: false,
      };

    case types.SET_CREDITS_AVAILABLE:
      return {
        ...state,
        loading: false,
        availableCredits: action.availableCredits,
        error: false,
      };

    case types.SET_CREDITS_USAGE:
      return {
        ...state,
        creditsUsage: action.creditsUsage,
        error: false,
      };

    case types.ERROR_CREDITS:
      return {
        ...state,
        availableCredits: 0,
        creditsUsage: [],
        error: true,
      };

    default:
      return state;
  }
};

export default credits;
