export const getId = state => state.search.id;

const header = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '
};
export const getTokenHeader = state => {

    if (state.auth.loginSuccess && state.auth.response && state.auth.response.tokenId) {
        return {
            ...header,
            'Authorization': `Bearer ${state.auth.response.tokenId}`
        };
    } else {
        return header;
    }
};
