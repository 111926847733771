
import { GET_INVOKE, SET_INVOKE, ERROR_INVOKE, POST_INVOKE} from '../constants/abmInvoke';

export const getInvoke = (id) => ({
    type: GET_INVOKE,
    id
});

export const setInvoke = ( user_id ) => ({
    type: SET_INVOKE,
    user_id
});

export const getInvokeFailed = () => ({
    type: ERROR_INVOKE
});

export const postAbmDetails = ( user_id , company_name, emails) => ({
    type: POST_INVOKE,
    user_id , company_name, emails
});
