import { GET_GENERATED_BLOGS, SET_GENERATED_BLOGS, ERROR_GENERATED_BLOGS } from '../constants/promoGeneratedBlog';

export const getGeneratedBlogs = () => ({
    type: GET_GENERATED_BLOGS,
});

export const setGeneratedBlogs = (response) => ({
    type: SET_GENERATED_BLOGS,
    response
});

export const getGeneratedBlogsFailed = () => ({
    type: ERROR_GENERATED_BLOGS
});
