import { primaryColor } from "assets/jss/material-dashboard-react";
import * as React from "react";

function SiteSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={30}
      // viewBox='0 0 40 30'
      viewBox="0 0 45 36"
      {...props}>
      <defs>
        <linearGradient id="prefix__a" x1={0.5} x2={0.5} y2={1} gradientUnits="objectBoundingBox">
          <stop offset={0} stopColor="#ff844f" />
          <stop offset={1} stopColor="#f74c54" />
        </linearGradient>
      </defs>
      <path
        data-name="Icon awesome-sitemap"
        d="M9,24.75H2.25A2.25,2.25,0,0,0,0,27v6.75A2.25,2.25,0,0,0,2.25,36H9a2.25,2.25,0,0,0,2.25-2.25V27A2.25,2.25,0,0,0,9,24.75ZM7.313,19.125h13.5V22.5h3.375V19.125h13.5V22.5h3.375V18.451a2.7,2.7,0,0,0-2.7-2.7H24.188v-4.5H27A2.25,2.25,0,0,0,29.25,9V2.25A2.25,2.25,0,0,0,27,0H18a2.25,2.25,0,0,0-2.25,2.25V9A2.25,2.25,0,0,0,18,11.25h2.813v4.5H6.638a2.7,2.7,0,0,0-2.7,2.7V22.5H7.313ZM25.875,24.75h-6.75A2.25,2.25,0,0,0,16.875,27v6.75A2.25,2.25,0,0,0,19.125,36h6.75a2.25,2.25,0,0,0,2.25-2.25V27A2.25,2.25,0,0,0,25.875,24.75Zm16.875,0H36A2.25,2.25,0,0,0,33.75,27v6.75A2.25,2.25,0,0,0,36,36h6.75A2.25,2.25,0,0,0,45,33.75V27A2.25,2.25,0,0,0,42.75,24.75Z"
        transform="translate(.001 -2.254)"
        fill={props && props.className.includes("activeIcon") ? primaryColor[5] : "#a0a0a0"}
      />
    </svg>
  );
}

export default SiteSVG;
