export const qParams = "id";
export const MARXEED_ID = "marxeed_transaction_id";

export function getQueryParam(search, param = qParams) {
  const params = new URLSearchParams(search);
  return params.get(param) || "";
}

export function objectToArray(array, props) {
  return array.map((obj) =>
    props.map((key) => {
      if (key.propName && key.transform) {
        return key.transform(obj[key.propName] + "");
      } else if (key.content && key.transform) {
        return key.transform(key.content + "");
      } else if (obj[key]) {
        return obj[key] + "";
      } else {
        return "";
      }
    })
  );
}

export function getLarestNumber(arr, prop) {
  let largest = arr[0][prop];

  for (var i = 1; i < arr.length; i++) {
    if (largest < arr[i][prop]) {
      largest = arr[i][prop];
    }
  }

  return largest;
}

export function validateEmail(mail) {
  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEx.test(String(mail).toLowerCase());
}

export function validateEmailCc(mail) {
  const regEx = /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g;
  return regEx.test(String(mail).toLowerCase());
}

export function validateObject(Obj) {
  if (typeof Obj !== "object") {
    return false;
  }

  let isValid = true;

  for (let prop in Obj) {
    if (!Obj[prop]) {
      isValid = false;
      break;
    }
  }

  return isValid;
}

export function getLocalTransacId() {
  const id = localStorage.getItem(MARXEED_ID);
  return id ? id : "";
}

export function setLocalTransacId(id) {
  localStorage.setItem(MARXEED_ID, id);
}

export function copyToClipboard(text = "") {
  //console.log("text", text);
  if (text) {
    // Copy to clipboad execution
    const textField = document.createElement("textarea");

    // String to be copied
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");

    // Removing unwanted textField after copy completion
    textField.remove();
    return true;
  }
}

export function getTreeTitleCopy(tree) {
  if (!tree || !tree.length) {
    return "";
  }

  let textToCopy = "";

  function traverseTree(treeObj) {
    const { title, children } = treeObj;

    if (title) {
      textToCopy += `${title}, `;
    }

    if (children && children.length) {
      children.forEach((child) => traverseTree(child));
    }
  }

  tree.forEach((child) => traverseTree(child));

  return textToCopy;
}

export function getExecDate(listh = [], id) {
  let date;
  let dateTimeFormat;
  const options = { day: "2-digit", year: "numeric", month: "long", formatMatcher: "basic" };
  const executedItem = listh.filter((item) => item.ID === id)[0];
  if (executedItem) {
    date = new Date(executedItem.CreatedAt);
    dateTimeFormat = new Intl.DateTimeFormat("en-US", options).format(date);
  }
  return dateTimeFormat;
}

export function formatDate(date) {
  // const newDate = date.split("-");
  // return newDate[1] + "-" + newDate[0] + "-" + newDate[2];
  let dateTimeFormat;
  const options = { day: "2-digit", year: "numeric", month: "short", hour:'2-digit',
  minute:'2-digit',formatMatcher: "basic" };
  const newDate = new Date(Date.parse(date));
  dateTimeFormat = new Intl.DateTimeFormat("en-US", options).format(newDate);
  return dateTimeFormat.toLocaleString();
}
