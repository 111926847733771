import { put, takeLeading, call, select } from "redux-saga/effects";
import { GET } from "../../utils/api.utility";

// import for Promo Quora
import { GET_PROMO_QUORA } from "../constants/promoQuora";
import { setPromoQuora, getPromoQuoraFailed } from "../actions/promoQuora";

//import for Promo Youtube
import { GET_PROMO_YOUTUBE } from "../constants/promoYoutube";
import { setPromoYoutube, getPromoYoutubeFailed } from "../actions/promoYoutube";

import { GET_PROMO_LINKEDIN } from "../constants/promoLinkedin";
import { setPromoLinkedin, getPromoLinkedinFailed } from "../actions/promoLinkedin";

//import for promo Ebook
import { GET_PROMO_EBOOK } from "../constants/promoEbook";
import { setPromoEbook, getPromoEbookFailed } from "../actions/promoEbook";

// import for Promo Hash Tags
import { GET_HASH_TAGS } from "../constants/promoHashTag";
import { setHashTags, getHashTagsFailed } from "../actions/promoHashTag";

// import for Promo Generated Blogs
import { GET_GENERATED_BLOGS } from "../constants/promoGeneratedBlog";
import { setGeneratedBlogs, getGeneratedBlogsFailed } from "../actions/promoGeneratedBlog";

// import for Promo Stack Overflow
import { GET_PROMO_STACK } from "../constants/promoStack";
import { setPromoStack, getPromoStackFailed } from "../actions/promoStack";

// import for Promo Reddit Overflow
import { GET_PROMO_REDDIT } from "../constants/promoReddit";
import { setPromoReddit, getPromoRedditFailed } from "../actions/promoReddit";

import { getTokenHeader } from "redux/utility";
import axios from "axios";
import { listAiBlogs } from "utils/generateAiBlog";

function* getPromoQuora({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const { quora, found, showing, authorized } = yield call(GET, `/quora?id=${id}`, header);

    if (id && quora) {
      yield put(setPromoQuora(id, quora, found, showing, authorized));
    } else {
      yield put(getPromoQuoraFailed());
    }
  } catch (error) {
    yield put(getPromoQuoraFailed());
  }
}

function* getPromoYoutube({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const youtube = yield call(GET, `/youtube?id=${id}`, header);
    if (id && youtube) {
      yield put(setPromoYoutube(youtube));
    } else {
      yield put(getPromoYoutubeFailed());
    }
  } catch (error) {
    yield put(getPromoYoutubeFailed());
  }
}

function* getPromoLinkedin({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const linkedin = yield call(GET, `/linkedin_groups?id=${id}`, header);

    if (id && linkedin) {
      yield put(setPromoLinkedin(linkedin));
    } else {
      yield put(getPromoLinkedinFailed());
    }
  } catch (error) {
    yield put(getPromoLinkedinFailed());
  }
}

function* getPromoEbook({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const { ebook, showing, authorized } = yield call(GET, `/ebooks?id=${id}`, header);
    if (id && ebook) {
      yield put(setPromoEbook(id, ebook, showing, authorized));
    } else {
      yield put(getPromoEbookFailed());
    }
  } catch (error) {
    yield put(getPromoEbookFailed());
  }
}

function* getGeneratedBlogs() {
  try {
    const header = yield select(getTokenHeader);
    const response = yield call(listAiBlogs);

    if (response) {
      yield put(setGeneratedBlogs(response));
    } else {
      yield put(getGeneratedBlogsFailed());
    }
  } catch (error) {
    yield put(getGeneratedBlogsFailed());
  }
}

function* getHashTags({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const response = yield call(GET, `/hashtags?id=${id}`, header);

    if (id && response) {
      yield put(setHashTags(id, response));
    } else {
      yield put(getHashTagsFailed());
    }
  } catch (error) {
    yield put(getHashTagsFailed());
  }
}

function* getPromoStack({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const { stackoverflow, found, showing, authorized } = yield call(GET, `/stackoverflow?id=${id}`, header);

    if (id && stackoverflow) {
      yield put(setPromoStack(id, stackoverflow, found, showing, authorized));
    } else {
      yield put(getPromoStackFailed());
    }
  } catch (error) {
    yield put(getPromoStackFailed());
  }
}

function* getPromoPeddit({ id }) {
  try {
    const header = yield select(getTokenHeader);
    const { reddit, found, showing, authorized } = yield call(GET, `/reddit?id=${id}`, header);

    if (id && reddit) {
      yield put(setPromoReddit(id, reddit, found, showing, authorized));
    } else {
      yield put(getPromoRedditFailed());
    }
  } catch (error) {
    yield put(getPromoRedditFailed());
  }
}

export default function* getPromoData() {
  yield takeLeading(GET_PROMO_QUORA, getPromoQuora);
  yield takeLeading(GET_PROMO_YOUTUBE, getPromoYoutube);
  yield takeLeading(GET_PROMO_LINKEDIN, getPromoLinkedin);
  yield takeLeading(GET_PROMO_EBOOK, getPromoEbook);
  yield takeLeading(GET_HASH_TAGS, getHashTags);
  yield takeLeading(GET_GENERATED_BLOGS, getGeneratedBlogs);
  yield takeLeading(GET_PROMO_STACK, getPromoStack);
  yield takeLeading(GET_PROMO_REDDIT, getPromoPeddit);
}
