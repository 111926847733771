import React, { useState, useEffect, useCallback } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import routes from "routes.js";
import styles from "assets/jss/material-dashboard-react/layouts/mainStyle.js";
import classObj from "./Main.module.css";
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import Payment from "./Payment/Payment";
import { onLocalAuthCheck, onCallCheckPoint, onLoggout } from "./../redux/actions/auth";
import { getSearchDetails } from "./../redux/actions/search";
import { getStatus } from "./../redux/actions/status";
import { connect } from "react-redux";
import { getQueryParam, getLocalTransacId, setLocalTransacId } from "./../utils/helper";
import AlertModal from "./../components/AlertModal/AlertModal";
//import Loader from "components/Loader/Loader.js";
import Snackbar from "../components/Snackbar/Snackbar";
import { Auth } from "aws-amplify";
import { axiosInstance, axiosBackendInstance } from "../api/axios/axios";
import { Snackbar as SnackbarNotification } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
let ps;

function getRoutes() {
  let viewRoutes = routes.map((prop, key) => {
    if (!prop.isNested) {
      return <Route exact path={prop.path} render={prop.render} key={prop.path + key} />;
    }


    return prop.routes.map((nestedRoute, key) => <Route exact path={nestedRoute.path} render={nestedRoute.render} key={nestedRoute.path + key} />);
  });

  // To spread nested arrays
  viewRoutes = [].concat(...viewRoutes);
  return viewRoutes;
}

const useStyles = makeStyles(styles);

function Main({
  getSearchDetails,
  getStatus,
  onLocalAuthCheck,
  onCallCheckPoint,
  appAuthChecked,
  loginSuccess,
  history,
  authDomain,
  snackbar,
  ...rest
}) {
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [payOpen, setPayOpen] = useState({
    open: false,
    isFree: false,
  });

  const handleDrawerToggle = useCallback(
    (open = !mobileOpen) => {
      setMobileOpen(open);
    },
    [setMobileOpen, mobileOpen]
  );

  const openPayment = useCallback(
    function (isFree = false) {
      setPayOpen({
        open: true,
        isFree,
      });
      handleDrawerToggle(false);
    },
    [setPayOpen, handleDrawerToggle]
  );

  const closePayment = useCallback(
    function () {
      setPayOpen({
        open: false,
        isFree: false,
      });
    },
    [setPayOpen]
  );

  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  React.useEffect(() => {
    const qParam = getQueryParam(rest.location.search, 'id');
    const videoLinkParam = getQueryParam(rest.location.search, 'video_link');
    var id = getLocalTransacId();

    if (qParam?.length > 0 || id?.length > 0) {
      if(qParam?.length > 0) {
        id = qParam
        setLocalTransacId(qParam);
      }
      
      if(videoLinkParam?.length === 0) {
        history.replace(`${rest.location.pathname?.length > 1 ? rest.location.pathname : "/home"}?id=${id}`);
      }
    } else if(qParam?.length === 0 && id?.length === 0 && videoLinkParam?.length === 0) {
      history.replace(`${rest.location.pathname?.length > 1 ? rest.location.pathname : "/home"}`);
    }

    if (!appAuthChecked) {
      onLocalAuthCheck();
    }
    // eslint-disable-next-line
  }, [onLocalAuthCheck, appAuthChecked]);

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  React.useEffect(() => {
    if (appAuthChecked) {
      const qParam = getQueryParam(rest.location.search);
      if (qParam) {
        getSearchDetails(qParam);
        getStatus(qParam);
        onCallCheckPoint(qParam);
      }
    }
  }, [appAuthChecked, rest.location.search, getSearchDetails, getStatus, onCallCheckPoint]);

  const [isAuthorizedUser, setIsAuthorizedUser] = useState(false);
  const [notification, setNotification] = useState(false);
  useEffect(() => {
    setIsAuthorizedUser(true);
    // const authorizedEmail = ["amit", "gg", "vijay", "gaurav", "sonia", "wahaj", "ankit"];
    // if (authDomain) {
    //   const orgDomain = authDomain.profileObj.email.split("@").pop();
    //   const user = authDomain.profileObj.email.split("@")[0];
    //   if ((orgDomain === "appliedaiconsulting.com" || orgDomain === "7targets.com") && authorizedEmail.includes(user)) {
    //     setIsAuthorizedUser(true);
    //   } else {
    //     setIsAuthorizedUser(false);
    //   }
    // 
  }, [loginSuccess, authDomain]);

  
  useEffect(() => {
    // Show user notification for mobile device
    if(window.innerWidth <= 800) {
      setNotification(true)
    }
  }, [])

    //************* IF AUTH is Logged out ***************//
    axiosInstance.interceptors.request.use(
      function (config) {
        return new Promise((resolve, reject) => {
            Auth.currentSession()
            .then((session) => {
                var idTokenExpire = session.getIdToken().getExpiration();
                window.localStorage.setItem(
                "idTokenExpireTimeOpenAi",
                new Date(idTokenExpire * 1000).toUTCString()
                );
                config.headers.Authorization =
                "Bearer " + session.getIdToken().getJwtToken();
                resolve(config);
            })
            .catch(async () => {
              try {
                await Auth.signOut();
                onLoggout();
              } catch (e) {
                console.error(e);
              }
              resolve(config);
            });
        });
      },
      (error) => error
  );

  axiosBackendInstance.interceptors.request.use(
    function (config) {
      return new Promise((resolve, reject) => {
          Auth.currentSession()
          .then((session) => {
            var idTokenExpire = session.getIdToken().getExpiration();
            window.localStorage.setItem(
            "idTokenExpireTimeBackend",
            new Date(idTokenExpire * 1000).toUTCString()
            );
            config.headers.Authorization =
            "Bearer " + session.getIdToken().getJwtToken();
            resolve(config);
          })
          .catch(async () => {
            try {
              await Auth.signOut();
              onLoggout();
            } catch (e) {
              console.error(e);
            }
            resolve(config);
          });
      });
    },
    (error) => error
  );

  return (
    <div className={classes.wrapper}>
      <Navbar qParam={getQueryParam(rest.location.search)} routes={routes} handleDrawerToggle={handleDrawerToggle} {...rest} />

      <Snackbar open={snackbar.open} message={snackbar.message} severity={snackbar.severity} path={snackbar.path} />
      <SnackbarNotification
        id="Snackbar"
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        open={notification}
      >
        <Alert onClose={() => setNotification(false)} severity={'warning'}>
          Please run marXeed fullscreen on PC. Interface is not designed for mobiles
        </Alert>
      </SnackbarNotification>

      <Sidebar
        routes={routes}
        logoText={"marXeed"}
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        color="primary"
        openPayment={openPayment}
        isAuthorizedUser={isAuthorizedUser}
        {...rest}
      />

      <div className={classes.mainPanel} ref={mainPanel}>
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        <div className={classes.content + " " + classObj.contentMT}>
          <div className={classes.container}>
            {appAuthChecked ? (
              <Switch>
                {getRoutes()}
                <Redirect
                  from="/"
                  to={{
                    ...rest.location,
                    pathname: "/home",
                  }}
                />
              </Switch>
            ) : null}
          </div>
        </div>
        <Footer />
      </div>
      {payOpen.open && <Payment open={payOpen.open} isFree={payOpen.isFree} onClose={closePayment} />}
      <AlertModal />
    </div>
  );
}

const mapStateToProps = ({ auth, snackbar }) => ({
  appAuthChecked: auth.appAuthChecked,
  loginSuccess: auth.loginSuccess,
  authDomain: auth.response,
  snackbar: snackbar,
});

const mapDispatchToProps = { getSearchDetails, getStatus, onLocalAuthCheck, onCallCheckPoint, onLoggout };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);