import * as types from '../constants/blogHeaders';

const initialState = {
    url: '',
    blogHeaders: [],
    authorized: false,
    loading: false,
    error: false
};

const blogHeaders = (state = initialState, {
    type,
    ...action
}) => {
    switch (type) {
        case types.GET_BLOG_HEADERS:
            return {
                ...state,
                loading: true,
                error: false
            };

        case types.SET_BLOG_HEADERS:
            return {
                ...state,
                url: action.url,
                blogHeaders: action.blogHeaders,
                authorized: action.authorized,
                loading: false,
                error: false
            };

        case types.ERROR_BLOG_HEADERS:
            return {
                ...state,
                url: '',
                blogHeaders: [],
                loading: false,
                error: true,
                authorized: false
            };

        default: return state;
    }
};

export default blogHeaders;