
import { GET_CCL_KEYWORDS, SET_CCL_KEYWORDS, ERROR_CCL_KEYWORDS } from '../constants/mindMap';

export const getCclKeywordsMindmap = (id) => ({
    type: GET_CCL_KEYWORDS,
    id
});

export const setCclKeywordsMindmap = (id, cclKeywordsMindmap, found, showing, authorized) => ({
    type: SET_CCL_KEYWORDS,
    id,
    cclKeywordsMindmap,
    found,
    showing,
    authorized
});

export const getCclKeywordsMindmapFailed = () => ({
    type: ERROR_CCL_KEYWORDS
});
